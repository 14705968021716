import React from "react";
import { ErrorBoundaryProps as ReactErrorBoundaryProps, ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import API from "src/js/API";
import defaultFallbackRender from "./defaultFallbackRender";

// const BLACKLISTED_URLS = ["127.0.0.1", "localhost", "dev.sellfinity.com", "192.168.1.201"];
const BLACKLISTED_URLS: string[] = [];
declare function FallbackRender(props: FallbackProps): React.ReactNode;
export type FallbackProps = {
	error: any;
	resetErrorBoundary: (...args: any[]) => void;
};

type ErrorBoundaryProps = Omit<ReactErrorBoundaryProps, "fallbackRender"> & {
	fallbackRender?: typeof FallbackRender;
};

const ErrorBoundary = (props: ErrorBoundaryProps) => {
	const { children, fallbackRender, fallback, FallbackComponent, ...rest } = props;
	const noFallback = !fallback && !FallbackComponent && !fallbackRender;

	const sendError = (errorObject) => {
		API.post("/api/frontend_exception.json", errorObject).catch((error) => console.error("error", error));
	};

	const onError = (error, info) => {
		//Don't send errors from blacklisted urls
		if (BLACKLISTED_URLS.includes(window.location.hostname as string)) {
			console.error(error, info);
			return;
		}
		const errorObject = {};

		if (error) Object.assign(errorObject, { error: error.stack });
		if (info) Object.assign(errorObject, info);
		if (window.location && window.location.href) Object.assign(errorObject, { location: window.location.href });
		if (window.location && window.location.hostname) Object.assign(errorObject, { hostname: window.location.hostname });
		if (window.navigator) Object.assign(errorObject, { browser: window.navigator.userAgent });

		Object.assign(errorObject, { date: new Date().toLocaleString("sv-SE"), type: "frontend" });
		sendError(errorObject);

		if (rest?.onError) rest.onError(error, info);
	};

	//Don't show error boundary in development. To easier debug errors
	if (BLACKLISTED_URLS.includes(window.location.hostname as string)) {
		return <>{children}</>;
	}

	return (
		<ReactErrorBoundary
			onError={onError}
			fallbackRender={fallbackRender || (noFallback ? defaultFallbackRender : undefined)}
			fallback={fallback as any}
			FallbackComponent={FallbackComponent as any}
			{...rest}
		>
			{children}
		</ReactErrorBoundary>
	);
};
export default ErrorBoundary;
