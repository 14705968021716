import React, { useState } from "react";
import styled from "styled-components";

const TestView = () => {
	const [count, setCount] = useState(0);

	return (
		<Wrapper>
			<Count count={count} />
			<Button
				onClick={() => {
					setCount((c) => ++c);
				}}
			/>
			<Text />
			<MemoText />
		</Wrapper>
	);
};

export default TestView;

const Button = (props) => {
	return <button {...props}>+1</button>;
};

const Count = ({ count }) => {
	return <div>{count}</div>;
};

const Text = () => {
	return <p>Some text here</p>;
};

const MemoText = React.memo(() => {
	return <p>Memorized: Some text here</p>;
});

const Wrapper = styled.div`
	padding: 5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	width: 100%;
`;
