import styled, { css, keyframes } from "styled-components";

const pulseFilterBrightness = keyframes`
  0% {filter: brightness(1)}
  40% {filter: brightness(1.25)}
  100% {filter: brightness(1)}
`;

export const FlexWrapper = styled.div<{
	onClick?: () => void;
}>`
	display: flex;
	justify-content: space-between;
`;

export const GridWrapper = styled.div<{
	onClick?: () => void;
	media?: boolean;
}>`
	display: grid;
	grid-template-columns: ${({ media }) => (media ? "minmax(0, auto) 1fr min-content" : "1fr min-content")};
	gap: 2rem;
`;

export const ItemCardWrapper = styled.div<{
	onClick?: (e:any) => void;
}>`
	padding: 0.9375rem 1.25rem;
	position: relative;
	width: 100%;
	background-color: var(--main2);
	border-radius: 6px;
	transition: filter 250ms;
	margin-bottom: 0.625rem;
	font-size: 12px;

	h3 {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		color: var(--main);

		.number_badge {
			margin-left: 0.625rem;
		}
	}

	.info-view__labels {
		justify-content: space-around;
	}

	.info-view__row__label {
		opacity: 0.75;
		font-weight: 500;
	}

	&:hover {
		filter: brightness(1.2);
	}

	.Polaris-Thumbnail {
		width: 100px;
		height: 100px;
	}
	.Polaris-Avatar {
		height: max-content;
	}

	.Polaris-Button.Polaris-Button--pressed {
		filter: brightness(1.5);
	}

	${({ onClick }) =>
		onClick &&
		css`
			cursor: pointer;
		`}

	&:empty {
		animation: ${pulseFilterBrightness} 2s linear infinite;
		cursor: wait;
	}
`;

export const RightWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: end;
`;
