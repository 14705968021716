import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import numberFormatter from "src/js/Utilities/numberFormatter";
import BarGraph from "src/js/components/Graphs/BarGraph";
import Select from "src/js/components/select/Select";
import styled from "styled-components";
import TargetDashboardContext from "../Context";
import { getColorFromIndex } from "src/js/Colors";

const TargetBarGraph = () => {
	const { data, targetConnections, target } = useContext(TargetDashboardContext);
	const stats = useMemo(() => data?.compare_against_connections, [data]);
	const ref = useRef<HTMLDivElement>(null);
	const [property, setProperty] = useState(stats?.options?.value_types?.[0]?.value || null);

	useEffect(() => {
		setProperty((c) => {
			if (stats?.options && !c) {
				return stats?.options?.value_types?.[0]?.value || null;
			}

			return c;
		});
	}, [stats]);

	const labels = stats?.labels || [];
	const series = targetConnections.map((targetConnection) => {
		return {
			name: targetConnection.label,
			data: targetConnection.period_values?.map((v) => v[property]),
			color: getColorFromIndex(data.target_connections.findIndex((c) => c.id === targetConnection.id)),
		};
	});

	const [pointWidth, setPointWidth] = useState(20);

	useLayoutEffect(() => {
		if (!ref.current) return;
		const width = ref.current.clientWidth;
		const computed = Math.max(Math.min(width / ((stats?.labels?.length || 0) * 2), 20), 2);
		setPointWidth(computed);
	}, [stats?.labels]);

	return (
		<Wrapper ref={ref}>
			<BarGraph
				// key={`invoiceGraph-${selectedYear}`}
				overlayText={stats?.error}
				stacked
				data={{
					series,
					labels,
				}}
				loading={false}
				formatter={(value) => {
					return `${numberFormatter({
						value,
						currency: "SEK",
						minimumFractionDigits: target?.number_of_decimals,
						maximumFractionDigits: target?.number_of_decimals,
						removeCurrencySymbol: true,
					})}${target?.suffix || ""}`;
				}}
				showLegends
				// pointWidth={getPointWidth()}
				pointWidth={pointWidth}
				settings={{
					stacked: false,
					dataLabels: false,
					grouped: false,
				}}
				headerRight={
					stats?.options?.value_types?.length && (
						<Select
							options={stats?.options?.value_types || []}
							value={property}
							onChange={(property) => {
								setProperty(property);
							}}
						/>
					)
				}
			/>
		</Wrapper>
	);
};
export default TargetBarGraph;
const Wrapper = styled.div``;
