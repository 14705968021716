import React, { Component } from "react";
import { PageActions, LegacyCard, FormLayout, TextField, Spinner } from "@shopify/polaris";

import httpBuildQuery from "http-build-query";
import { withTranslation } from "react-i18next";
import API from "../../API";
import NumberBadge from "../../components/NumberBadge.js";
import Colors, { colorToRgba } from "../../Colors.js";
import StatsBoxWrapper from "../../components/statsBoxes";
import { CheckmarkWithBottomIcon, ListIcon, CheckmarkIcon, SadFaceIcon } from "../../icons";
import IconWithBackround from "../../components/IconWithBackround.js";
import IndexTable from "src/js/components/IndexTable";
import SkeletonPage from "src/js/components/Skeletons/Page";
import Toast from "src/js/components/Toast";

class AudienceEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lod: true,
			id: props.match.params.id,
			form: {},
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, lod: true });
		API.get("/api/audiences/" + id + ".json")
			.then((result) => {
				this.setState({
					lod: false,
					form: result.data.audience,
					title: result.data.audience.title,
				});
				const params = {};
				for (let i = 0; i < result.data.audience.filters.length; i++) {
					params[result.data.audience.filters[i].key] = result.data.audience.filters[i].value;
				}
				this.props.history.replace(this.props.history.location.pathname + "?" + httpBuildQuery(params).replace(/\+/g, "%20"));
				// this.updateSearch(result.data.audience.filters, this.state.searchValue);
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	removeAudience() {
		this.setState({ saving: true });
		API.delete("/api/audiences/" + this.state.id + ".json")
			.then((result) => {
				this.props.history.goBack();
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	getValidationBadge(status) {
		// bounced, processing, not_approved, unsubscribed, approved, verified
		if (status == "verified") {
			return <NumberBadge color={Colors.green}>{this.props.t("audience.statuses.verified", "verifierad")}</NumberBadge>;
		} else if (status == "bounced") {
			return <NumberBadge color={Colors.red}>{this.props.t("audience.statuses.bounced", "studsad")}</NumberBadge>;
		} else if (status == "not_approved") {
			return <NumberBadge color={Colors.red}>{this.props.t("audience.statuses.not_approved", "ej godkänd")}</NumberBadge>;
		} else if (status == "unsubscribed") {
			return <NumberBadge color={Colors.orange}>{this.props.t("audience.statuses.unsubscribed", "avregistrerad")}</NumberBadge>;
		} else if (status == "approved") {
			return <NumberBadge color={Colors.green}>{this.props.t("audience.statuses.approved", "godkänd")}</NumberBadge>;
		} else if (status == "processing") {
			return <NumberBadge color={Colors.grey}>{"-"}</NumberBadge>;
		}

		return <NumberBadge color={Colors.lightblue}>{this.props.t(`audience.statuses.${status}`, status)}</NumberBadge>;
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/audiences/" + this.state.id + ".json", { title: this.state.title, filters: this.state.form.filters })
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				Toast.success(this.props.t("audience.response.created", "Målgrupp sparades"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				Toast.error(error);
			});
	}

	render() {
		if (this.state.lod) {
			return <SkeletonPage />;
		}

		const filters = [
			{
				key: "email_opted",
				label: this.props.t("audience.filters.email_opt.label", "Är e-postkontakt"),
				type: "select",
				options: [
					{ label: this.props.t("common.terms.yes", "Ja"), value: 1 },
					{ label: this.props.t("common.terms.no", "Nej"), value: 0 },
				],
				shortcut: true,
			},
			{
				key: "status",
				label: this.props.t("audience.filters.status.label", "Status"),
				operatorText: this.props.t("common.terms.is", "är"),
				type: "select",
				options: [
					{ value: "bounced", label: this.props.t("audience.filters.status.bounced", "studsad") },
					{ value: "not_approved", label: this.props.t("audience.filters.status.not_approved", "ej godkänd") },
					{ value: "unsubscribed", label: this.props.t("audience.filters.status.unsubscribed", "avregistrerad") },
					{ value: "approved", label: this.props.t("audience.filters.status.approved", "godkänd") },
					{ value: "verified", label: this.props.t("audience.filters.status.verified", "verifierad") },
				],
				shortcut: true,
			},
		];

		const columns = [
			{
				handle: "EMAIL",
				label: this.props.t("audience.columns.email.label", "Email"),
				sortable: true,
				render: (item) => item.email,
			},
			{
				handle: "NAME",
				label: this.props.t("audience.columns.name.label", "Namn"),
				sortable: true,
				render: (item) => item.name,
			},
			{
				handle: "STATUS",
				label: this.props.t("audience.columns.status.label", "Status"),
				sortable: false,
				render: (item) => this.getValidationBadge(item.validation_status),
			},
		];

		const getBoxTitle = (key) => {
			if (!this.state.form) return <Spinner size="small" />;
			if (this.state.form && key in this.state.form) {
				return `${this.state.form[key]} st`;
			}
			return "-";
		};

		return (
			<div>
				<IndexTable
					filterKey="email_contacts"
					history={this.props.history}
					// setRefreshHandler={(refreshHandler) => {
					// 	this.refresh = refreshHandler;
					// }}
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						disabled: this.state.loading || !this.state.title,
						loading: this.state.saving,
						onClick: this.saveForm.bind(this),
					}}
					extraHeader={
						<React.Fragment>
							<StatsBoxWrapper
								perRow={4}
								items={[
									{
										title: getBoxTitle("count"),
										text: this.props.t("audience.box.total.text", "Total"),
										icon: <IconWithBackround icon={<ListIcon color={Colors.blue} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
									},
									{
										title: getBoxTitle("imported_count"),
										text: this.props.t("audience.box.imported_count.text", "epostkontakter"),
										icon: <IconWithBackround icon={<CheckmarkIcon color={Colors.green} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
									},
									{
										title: getBoxTitle("approved_count"),
										text: this.props.t("audience.box.approved_count.text", "Godkända"),
										icon: (
											<IconWithBackround icon={<CheckmarkWithBottomIcon color={Colors.purple} />} backgroundColor={colorToRgba(Colors.purple, 0.2)} />
										),
									},
									{
										title: getBoxTitle("verified_count"),
										text: this.props.t("audience.box.verified_count.text", "Verifierade"),
										icon: <IconWithBackround icon={<SadFaceIcon color={Colors.yellow2} />} backgroundColor={colorToRgba(Colors.yellow2, 0.2)} />,
									},
								]}
							/>
							<LegacyCard>
								<LegacyCard.Section>
									<FormLayout>
										<TextField
											label={this.props.t("audience.fields.name.label", "Namn på målgrupp")}
											value={this.state.title}
											onChange={(v) => this.setState({ title: v })}
										/>
									</FormLayout>
								</LegacyCard.Section>
							</LegacyCard>
						</React.Fragment>
					}
					title={this.state.title}
					backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
					// savedSearchHandle="email_campaigns"
					resourceUrl={"/api/email_contacts.json?audience_id=" + this.state.id}
					resourceHandle="contacts"
					resourceName={{
						plural: this.props.t("contact.plural", "kontakter"),
						singular: this.props.t("contact.singular", "kontakt"),
					}}
					columns={columns}
					filters={filters}
					limit={10}
				/>

				<PageActions
					secondaryActions={[
						{
							content: this.props.t("common.actions.remove", "Ta bort"),
							destructive: true,
							loading: this.state.saving,
							onAction: this.removeAudience.bind(this),
						},
					]}
				/>
			</div>
		);
	}
}
export default withTranslation(["audience", "common"], { withRef: true })(AudienceEdit);
