import { useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import PricelistContext from "./context";
import { InfoWrapper } from "../CustomFields/styles";
import ResourceListManager from "../ResourceListManager";
import ItemCard from "src/js/components/ItemCard";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import getFirstRelationship from "src/js/Utilities/contact/getFirstRelationship";
import { store } from "src/js/store";

type PricelistCustomersProps = {
	history: any;
} & WithTranslation;

const PricelistCustomers = ({ history, t }: PricelistCustomersProps) => {
	const { id } = useContext(PricelistContext);

	const renderItem = (item: any) => {
		return (
			<ItemCard
				title={
					<>
						{item.name}
						{item.connected ? (
							<Badge color={Colors.green}>{t("common.connected", "Ansluten")}</Badge>
						) : (
							<Badge color={Colors.grey}>{t("common.notConnected", "Ej Ansluten")}</Badge>
						)}
					</>
				}
				leftColumn={[
					{
						label: t("contacts.field.type", "Typ"),
						value: item.is_company ? t("common.company", "Företag") : t("common.person", "Kontaktperson"),
					},
					{
						label: t("contacts.field.email", "Mailadress"),
						value: <a href={`mailto:${item.email}`}>{item.email}</a>,
					},
					{
						label: t("contacts.field.phone", "Telefon"),
						value: <a href={`tel:${item.phone}`}>{item.phone}</a>,
					},
					{
						label: t("contacts.field.orgnr", "Orgnr"),
						value: item.orgnr,
						enabled: item.is_company,
					},
					{
						label: t("contacts.field.company", "Företag"),
						value: item.company,
						enabled: !item.is_company,
					},
					{
						label: t("contacts.field.relationship", "Ansvarig medarbetare"),
						value: getFirstRelationship(item)?.user?.name,
						enabled: store.getState().fields.length <= 1,
					},
				]}
				rightColumn={[
					{
						label: t("contacts.field.address", "Adress"),
						value: item.street || item.address1,
						enabled: item.is_company,
					},
					{
						label: t("contacts.field.zip", "Postnummer"),
						value: item.zip,
						enabled: item.is_company,
					},
					{
						label: t("contacts.field.city", "Stad/ort"),
						value: item.city,
						enabled: item.is_company,
					},
					{
						label: t("contacts.field.cutomernumber", "Kundnummer"),
						value: item.customer_number,
						enabled: item.customer_number,
					},
				]}
			/>
		);
	};

	const filters = [
		{
			key: "is_company",
			label: t("contacts.field.type", "Typ"),
			type: "select",
			options: [
				{ label: t("pricelist.filters.company", "Företag"), value: "1" },
				{ label: t("pricelist.filters.person", "Kontaktperson"), value: "0" },
			],
			shortcut: true,
		},
	];

	return (
		<InfoWrapper>
			<ResourceListManager
				onClick={(item) => {
					history.push(`/admin/contacts/${item.id}`, {
						data: item,
					});
				}}
				params={{ pricelist_id: id }}
				resourceHandle={"contacts"}
				filterKey={"contacts"}
				history={history}
				renderItem={renderItem}
				filters={filters}
				resourceUrl="/api/contacts.json"
				resourceName={{ singular: t("customers.singular", "Kund"), plural: t("customers.plural", "Kunder") }}
				addRemoveUrl={`/api/pricelists/${id}/contacts.json`}
			/>
		</InfoWrapper>
	);
};
export default withTranslation(["pricelist", "common"])(PricelistCustomers);
