import moment from "moment";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "src/js/components/select/Select";
import BarGraph from "src/js/components/Graphs/BarGraph";
import { currencyFormatter } from "src/js/Utilities";

type InvoiceGraphProps = {
	data: any;
	t: any;
	loading?: boolean;
	onChangeYear?: (year: string) => void;
	selectedYear?: string;
};

const InvoiceGraph = ({ data, t, loading, onChangeYear, selectedYear: propsSelectedYear }: InvoiceGraphProps) => {
	const [selectedYear, setSelectedYear] = useState(propsSelectedYear || moment().format("YYYY"));

	const labels = data?.[selectedYear]?.labels || [];
	const series = data?.[selectedYear]?.series || [];

	const yearOptions = (() => {
		const years = Object.keys(data || {}).filter((k) => !Number.isNaN(parseInt(k)));

		if (!years.includes(moment().format("YYYY"))) years.push(moment().format("YYYY"));
		// if (!years.includes(moment().subtract(1, "year").format("YYYY"))) years.push(moment().subtract(1, "year").format("YYYY"));

		return years.sort((a, b) => parseInt(b) - parseInt(a)).map((year) => ({ value: year, label: year }));
	})();

	useEffect(() => {
		if (propsSelectedYear) {
			setSelectedYear(propsSelectedYear);
		}
	}, [propsSelectedYear]);

	const placeholder = !yearOptions?.find((option) => option.value == selectedYear) ? selectedYear : t("graph.invoice.select_year", "Välj år");

	return (
		<BarGraph
			// key={`invoiceGraph-${selectedYear}`}
			stacked
			data={{
				series,
				labels,
			}}
			title={t("graph.invoice.title", "Fakturering")}
			loading={!!loading}
			headerRight={
				<Select
					value={selectedYear}
					onChange={(e) => {
						setSelectedYear(e);
						onChangeYear?.(e);
					}}
					options={yearOptions}
					placeholder={placeholder}
				/>
			}
			formatter={(value) => {
				return currencyFormatter({
					value,
					currency: "SEK",
					maximumFractionDigits: 0,
				});
			}}
			numberOfLegendsToShow={5}
		/>
	);
};

export default withTranslation(["graph"])(InvoiceGraph);
