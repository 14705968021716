/* eslint-disable no-restricted-syntax */
export default (state = {}, action) => {
	switch (action.type) {
		case "CLEAR_BOARD_ROWS": {
			const rows = {};
			for (const i in state) {
				const row = state[i];

				if (row.board_id != action.board_id) {
					const rowId = row.id;
					rows[rowId] = row;
				}
			}

			return rows;
		}
		case "SET_BOARD_ROWS": {
			const rows = Object.assign({}, state);
			for (let i = 0; i < action.rows.length; i++) {
				const row = action.rows[i];

				const rowId = row.id;
				rows[rowId] = row;
			}
			return rows;
		}
		case "RESET_ALL_BOARD_ROWS": {
			return {};
		}
		case "ADD_BOARD_ROW": {
			const newState = Object.assign({}, state);

			if (!action.row.id) {
				newState["r" + action.row.ref] = action.row;
			} else if (!(action.row.id in newState)) {
				// Perhaps use ref for newly created rows
				if ("r" + action.row.ref in newState) {
					delete newState["r" + action.row.ref];
				}
				for (const i in newState) {
					if (newState[i].group_id == action.row.group_id && newState[i].position >= action.row.position) {
						newState[i].position++;
					}
				}
				newState[action.row.id] = action.row;
			} else {
				newState[action.row.id] = action.row;
			}

			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		case "UPDATE_BOARD_POSITION_DOWN": {
			const newState = Object.assign({}, state);
			for (const i in newState) {
				if (newState[i].group_id === action.group_id && newState[i].position >= action.position) {
					const newItem = Object.assign({}, newState[i]);
					newItem.position++;
					newState[i] = newItem;
				}
			}
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		case "UPDATE_BOARD_POSITION_UP": {
			const newState = Object.assign({}, state);
			for (const i in newState) {
				if (newState[i].group_id === action.group_id && newState[i].position >= action.position) {
					const newItem = Object.assign({}, newState[i]);
					newItem.position--;
					newState[i] = newItem;
				}
			}
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		case "UPDATE_BOARD_ROW": {
			const newState = Object.assign({}, state);
			let originPosition;
			let originGroupId;
			if (!(action.row.id in newState)) {
				// Perhaps use ref for newly created rows
				if ("r" + action.row.ref in newState) {
					originPosition = newState["r" + action.row.ref].position;
					originGroupId = newState["r" + action.row.ref].group_id;
					delete newState["r" + action.row.ref];
				}
			} else {
				originPosition = newState[action.row.id].position;
				originGroupId = newState[action.row.id].group_id;
			}

			if (originPosition != action.row.position) {
				// Row moved
				for (const i in newState) {
					if (newState[i].group_id == originGroupId && newState[i].position >= originPosition) {
						newState[i].position--;
					}
					if (newState[i].group_id == action.row.group_id && newState[i].position >= action.row.position) {
						newState[i].position++;
					}
				}
			}
			newState[action.row.id] = Object.assign({}, action.row);
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		case "REMOVE_BOARD_ROW": {
			const newState = Object.assign({}, state);
			if (action.row.id in newState) {
				const originPosition = newState[action.row.id].position;
				const originGroupId = newState[action.row.id].group_id;
				for (const i in newState) {
					if (newState[i].group_id == originGroupId && newState[i].position >= originPosition) {
						newState[i].position--;
					}
				}
				delete newState[action.row.id];
			}
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		case "REMOVE_BOARD_ROWS_BY_ID": {
			const newState = Object.assign({}, state);

			action.ids.forEach((id) => {
				// const row = newState[id];

				// if (row) {
				// 	const originPosition = row.position;
				// 	const originGroupId = row.group_id;
				// 	for (const i in newState) {
				// 		if (newState[i].group_id == originGroupId && newState[i].position >= originPosition) {
				// 			newState[i].position--;
				// 		}
				// 	}
				// }

				delete newState[id];
			});

			return newState;
		}
		default:
			return state;
	}
};
