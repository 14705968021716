import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Collapsible, Icon, Link } from "@shopify/polaris";

import { EditMajor, JobsMajor, CircleCancelMajor, MobileChevronMajor } from "@shopify/polaris-icons";
import { useSelector, useDispatch } from "react-redux";
import API from "../../../API";
import ContactSelector from "../../../components/ContactSelector.js";
import IntegrationButtons from "../../../components/IntegrationButtons.js";
import RemoveModal from "../../../components/remove_modal.js";
import Skeleton1Card from "../../../components/skeleton-1card.js";
import ProfileAvatar from "../../../components/ProfileAvatar.js";
import InfoView from "../../../components/InfoView.js";
import TagSelector from "../../../components/TagSelector.js";
import IconWithBackround from "../../../components/IconWithBackround.js";
import Banner from "../../../components/banner";
import RoadSignIcon from "../../../icons/RoadSignIcon.js";
import ContactRelationships from "../../../components/ContactRelationships.js";
import RelationshipSelector from "../../../components/RelationshipSelector.js";
import SellerSelector from "../../../components/SellerSelector.js";
import getFirstRelationship from "../../../Utilities/contact/getFirstRelationship";
import { ContactTypeIndicator, OuterWrapper, Wrapper } from "./styles";
import useContact from "src/js/hooks/useContact";
import Button from "src/js/components/Button";
import ContactModal from "../ContactModal";
import Toast from "src/js/components/Toast";

type ContactCardProps = {
	contact: any;
	children?: any;
	expanded?: boolean;
	gotoContact?: any;
	onUpdate?: any;
	onChange?: any;
	external?: any;
	link?: any;
	loading?: any;
	onCreateNoSave?: (contact: Partial<ContactType>) => void;
};

const ContactCard = forwardRef((props: ContactCardProps, ref) => {
	const { contact = {}, updateContact, t, refreshContact, isInitialLoading } = useContact(props.contact?.id, props.contact || {});

	const { children, expanded: propsExpanded, gotoContact, onUpdate, onChange, external, link, loading = isInitialLoading, onCreateNoSave } = props;

	const [expanded, setExpanded] = useState(propsExpanded);
	const [selectedTag, setSelectedTag] = useState<any>(null);

	const storeFields = useSelector((state: any) => state.fields);
	const storeCountries = useSelector((state: any) => state.fields);
	const storeContactFields = useSelector((state: any) => state.contact_metafields);
	const [contactModalIsOpen, setContactModalIsOpen] = useState(false);

	const dispatch = useDispatch();

	const handleUpdateContact = async (data) => {
		const result = await updateContact(data, contact?.id);

		if (onUpdate) onUpdate(result);

		return result;
	};

	const addTag = async (tag, i) => {
		const tags = [...(contact.tags || []), tag];

		return await handleUpdateContact({
			tags,
		});
	};

	const preRemoveTag = (tag) => () => {
		setSelectedTag(tag);
	};

	const doRemoveTag = async () => {
		const tags = contact.tags?.filter((tag) => tag.title !== selectedTag.title) || [];
		const newTags = tags.filter((tag) => tag.title !== selectedTag.title);

		const result = await handleUpdateContact({
			tags: newTags,
		});

		if (result) setSelectedTag(null);
	};

	const gotoParent = () => {
		gotoContact(contact.parent);
	};

	const updateCompany = async (parent) => {
		const res = await handleUpdateContact({
			parent,
		});

		return res;
	};

	const createCompany = (contact) => {
		if (onUpdate) onUpdate(contact);

		refreshContact();
	};

	const getMetafieldValue = (metafield) => {
		const metafieldValue = contact?.metafields?.find((f) => f.metafield_id == metafield.id);

		return metafieldValue ? metafieldValue.value : metafield?.type == "boolean" ? false : "";
	};

	const toggleContactModalState = (state = !contactModalIsOpen) => {
		setContactModalIsOpen(state);
	};

	const changeRelationship = (field) => async (relationship) => {
		const fields = contact.fields.map((f) => {
			if (f.field_id == field.id) {
				f.relationship = relationship;
			}
			return f;
		});

		onChange?.({
			...contact,
			fields,
		});

		if (!contact.id) return;

		try {
			const result = await API.put(
				"/api/contacts/" + contact.id + "/fields/" + field.id + "/relationship.json",
				{
					relationship,
					is_company: contact.is_company,
				},
				{ params: {} }
			);

			dispatch({
				type: "UPDATE_FIELD",
				field: result.data.contact.fields.find((f) => f.field_id == field.id),
			});

			if (onUpdate) {
				onUpdate(result.data.contact);
			}

			refreshContact();

			Toast.success(t("contact.responses.relation.updated", "Relation uppdaterades"));
		} catch (e) {
			Toast.error(e);
		}
	};

	const changeRelationshipUser = (field) => async (user) => {
		const fields = contact.fields.map((f) => {
			if (f.field_id == field.id) {
				f.user = user;
			}
			return f;
		});

		onChange?.({
			...contact,
			fields,
		});

		if (!contact.id) return;

		try {
			const result = await API.put(
				"/api/contacts/" + contact.id + "/fields/" + field.id + "/user.json",
				{
					user,
					is_company: contact.is_company,
				},
				{ params: {} }
			);

			Toast.success(t("contact.responses.relation.updated", "Relation uppdaterades"));

			if (onUpdate) {
				onUpdate(result.data.contact);
			}

			dispatch({
				type: "UPDATE_FIELD",
				field: result.data.contact.fields.find((f) => f.field_id == field.id),
			});

			refreshContact();
		} catch (e) {
			Toast.error(e);
		}
	};

	const metafields: any[] =
		storeContactFields
			?.map((f) => {
				const value = getMetafieldValue(f);
				return value ? { metafield: f, value } : null;
			})
			.filter(Boolean) || [];

	const address = contact.street || contact.address || contact.address1;
	const topRelationship = getFirstRelationship(contact);
	const fieldForTopRelationship = storeFields.find((f) => f.id == topRelationship?.field_id);

	const info = [
		{
			label: t("contact.fields.company.label", "Företag"),
			enabled: !contact.is_company,
			component: (
				<div style={{ display: "flex", gap: "1.2500rem" }}>
					<ContactSelector
						caption={t("contact.fields.company.caption", "Lägg till företag")}
						contact={contact}
						onChange={updateCompany}
						onCreateContact={createCompany}
						hidePerson
						hideIcon
						link
						lock
						gotoContact={gotoParent}
						external={external}
						disabled={contact?.disabled}
						// element={(props) => <Button {...props}>{props.children}</Button>}
					/>
				</div>
			),
		},
		{
			label: t("contact.fields.email.label", "Mailadress"),
			value: contact.email,
			type: "email",
		},
		{
			label: t("contact.fields.phone.label", "Telefonnummer"),
			value: contact.phone,
			type: "phone",
		},
		{
			label: t("contact.fields.mobile_phone.label", "Mobilnummer"),
			value: contact.mobile_phone,
			type: "phone",
		},
		{
			label: t("contact.fields.title.label", "Befattning/Roll"),
			value: contact.title,
			enabled: !contact.is_company,
		},
		{
			label: t("contact.fields.orgnr.label", "Org nr"),
			value: contact.orgnr,
			enabled: contact.is_company,
			type: "orgnr",
		},
		{
			label: t("contact.fields.address.label", "Adress"),
			value: address ? address + ", " + contact.zip + " " + contact.city : "",
		},
		{
			label: t("contact.fields.customer_number.label", "Kundnummer"),
			value: contact.customer_number,
		},
		{
			label: t("contact.fields.contact_user.label", "Ansvarig medarbetare"),
			enabled: storeFields.length <= 1,
			component: <SellerSelector noPadding user={topRelationship?.user} onChange={changeRelationshipUser(fieldForTopRelationship)} clearable plain />,
		},
	]
		.concat(
			metafields.map((metafield) => ({
				label: metafield.metafield.title,
				value:
					metafield.metafield.type == "boolean" ? (metafield.value ? t("common.terms.yes", "Ja") : t("common.terms.no", "Nej")) : metafield.value,
			}))
		)
		.concat([
			{
				label: t("contact.fields.note.label", "Anteckning"),
				value: contact.note,
				enabled: contact.note,
				style: {
					alignItems: "start",
				},
			},
		] as any)
		.filter((i) => (!("enabled" in i) || i.enabled) && (i.value || i.component));

	const nrFieldsToShow = 4;

	const contactInfo = info.slice(0, nrFieldsToShow);
	const contactInfoExpanded = info.slice(nrFieldsToShow);

	useImperativeHandle(ref, () => ({
		toggleContactModalState,
	}));
	// return null;

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<OuterWrapper>
			<ContactTypeIndicator contact={contact} />

			<Wrapper contact={contact as any}>
				{contact.is_visitor && (
					<Banner
						status="info"
						title={t("contact.visitor.title", "Ej importerad")}
						style={{ marginBottom: "0.9375rem" }}
						dismissible
						actions={[
							{
								primary: true,
								content: t("contact.visitor.actions.import", "Importera") as string,
								onAction: toggleContactModalState,
							},
						]}
					>
						<p>{t("contact.visitor.text1", "Denna kontakt är ej importerad i crm:et.")}</p>
						<p>{t("contact.visitor.text2", "Klicka på importera nedan och sen spara för att importera kontakten.")}</p>
					</Banner>
				)}

				<div className={"contact-card-header " + (contact.is_company ? "company" : "people")}>
					<div className="contact_section header" style={{ marginBottom: "1.2500rem" }}>
						{contact.is_company ? <IconWithBackround icon={JobsMajor} /> : <ProfileAvatar user={contact} size={50} />}
						<div className="contact_section_content">
							{/* {<h2 className="contact_section_title">{title}</h2>} */}
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<div>
									<h1
										className="contact_section_title"
										style={{
											alignItems: "center",
											display: "flex",
											gap: "0.6250rem",
											color: "var(--textColor)",
										}}
									>
										{link ? <Link onClick={gotoContact}>{contact.name}</Link> : <span>{contact.name}</span>}

										{contact?.id && (
											<RelationshipSelector
												disabled={contact?.removed}
												relationship={topRelationship?.relationship}
												onChange={changeRelationship(fieldForTopRelationship)}
												onClick={
													storeFields?.length > 1
														? () => {
																if (storeFields && storeFields.length > 1) {
																	Toast.warning(t("contact.text.change_relation_else", "Du behöver ändra kundrelation under avdelningar"));
																}
														  }
														: null
												}
											/>
										)}
									</h1>

									<div className="tags">
										{contact.tags && (
											<React.Fragment>
												{`${t("contact.tags.plural", "Taggar")}: `}
												{contact.tags.map((prop, key, self) => (
													<div className="tag" key={key}>
														{key > 0 && key < self.length ? ", " : ""}
														{prop.title}
														{!contact?.removed && <Button onClick={preRemoveTag(prop)} destructive icon={CircleCancelMajor} />}
													</div>
												))}
											</React.Fragment>
										)}
										{contact.id && !contact.fromRoaring && !contact?.removed && (
											<span
												className="TagSelector"
												style={{
													marginLeft: contact.tags && contact.tags.length ? "0.3125rem" : "0",
												}}
											>
												<TagSelector caption="" contact={contact} onCreate={addTag} />
											</span>
										)}
									</div>
								</div>
								{/* <a href={`https://maps.google.com/?q=${form}`}> */}
								<div
									style={{
										height: "100%",
										display: "flex",
										flexDirection: "column",
										alignItems: "end",
									}}
								>
									{!contact?.removed && (
										<span className="edit-button" style={{ marginBottom: "0.6250rem" }}>
											<Button onClick={toggleContactModalState} icon={EditMajor}>
												{t("common.actions.change", "Ändra")}
											</Button>
										</span>
									)}
									{contact.street ? (
										<a
											className="googlemaps_link"
											href={`https://www.google.com/maps/search/?api=1&query=${contact.street}, ${contact.city} ${contact.zip} ${(() => {
												const country = storeCountries?.find((c) => c.value === contact.country);
												if (country) return country.label;
												return "";
											})()}`}
											target="_blank"
											rel="noreferrer"
										>
											<RoadSignIcon />
											{t("contact.terms.directions", "Vägbeskrivning")}
										</a>
									) : (
										<div />
									)}
								</div>
							</div>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							position: "relative",
						}}
					>
						{loading && <Skeleton1Card />}
						<InfoView labelWidth="125px" details={contactInfo} fullView />
						{!propsExpanded && contactInfoExpanded && !!contactInfoExpanded.length && (
							<div className="expand-card__btn" onClick={() => setExpanded((c) => !c)}>
								{expanded
									? t("contact.actions.hide_info", "Dölj alla kontaktuppgifter")
									: t("contact.actions.show_info", "Visa alla kontaktuppgifter")}
								<span
									style={{
										rotate: expanded ? "90deg" : "270deg",
										transition: "rotate 350ms",
									}}
								>
									<Icon source={MobileChevronMajor} />
								</span>
							</div>
						)}
					</div>

					<Collapsible id="contact_info" open={!!expanded}>
						<InfoView details={contactInfoExpanded} labelWidth="125px" style={{ marginTop: "0.6250rem" }} fullView />
					</Collapsible>
					{contact.id && <IntegrationButtons contact={contact} external={external} />}
					{!!storeFields?.length && contact?.id && (
						<div className="gap">
							<div>
								<h3
									style={{
										fontWeight: 700,
										marginBottom: "0.3125rem",
										marginTop: "1.0938rem",
									}}
								>
									{t("contact.terms.deals_and_contact_user", "Affärsområde & ansvariga medarbetare")}
								</h3>
								<ContactRelationships
									// ref={(ref) => {
									// 	this.contactRelationships = ref;
									// }}
									contact={contact}
									compact
									onUpdate={(contact) => {
										onChange?.(contact);
										refreshContact();
									}}
									loading={loading}
								/>
							</div>
							{/* {this.props.children} */}
						</div>
					)}
				</div>

				{children}

				<RemoveModal
					open={!!selectedTag}
					onClose={() => {
						setSelectedTag(null);
					}}
					numItems={1}
					objectName={selectedTag?.title}
					onConfirm={doRemoveTag}
					resourceName={{
						singular: t("contact.tags.singular", "tagg"),
						plura: t("contact.tags.plural", "taggar"),
					}}
				/>

				<ContactModal
					onCreateNoSave={onCreateNoSave}
					open={contactModalIsOpen}
					// onUpdate={(contact: ContactFormType) => {
					// 	onUpdate?.(contact);
					// }}
					// onCreate={(newContact: ContactFormType) => {
					// 	if (newContact?.children) {
					// 		for (let i = 0; i < newContact.children.length; i++) {
					// 			if (newContact.children[i].id == contact.id) {
					// 				gotoContact(newContact);
					// 			}
					// 		}
					// 	}
					// }}
					company={contact?.is_company ? contact : contact?.parent}
					contact={contact}
					// contact={this.state.selectedContact || (this.state.contact.is_company ? this.state.form : this.state.contact.parent)}
					onClose={() => toggleContactModalState(false)}
				/>
			</Wrapper>
		</OuterWrapper>
	);
});

export default ContactCard;
