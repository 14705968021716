import { Avatar } from "@shopify/polaris";
import React from "react";
import { withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import IndexTable from "src/js/components/IndexTable";
import ItemCard from "src/js/components/ItemCard";
import truncateString from "src/js/Utilities/truncateString";

const GroupIndex = ({ history, t }) => {
	const renderItem = (item: any) => {
		return (
			<ItemCard
				media={<Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />}
				title={<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>{item.name}</div>}
				leftColumn={[
					{
						label: t("groups.field.description.label", "Beskrivning"),
						value: truncateString(item.description),
					},
					{
						label: t("groups.field.status.label", "Status"),
						value: item.disable ? (
							<span style={{ fontWeight: 600, color: Colors.red }}>{t("groups.fields.disabled.value", "Inaktiv")}</span>
						) : (
							<span style={{ fontWeight: 600, color: Colors.green }}>{t("groups.fields.enabled.value", "Aktiv")}</span>
						),
					},
					{
						label: t("groups.field.users_display", "Användare"),
						value: item.users_display,
					},
				]}
				rightColumn={[]}
			/>
		);
	};

	return (
		<IndexTable
			history={history}
			title={t("groups.title", "Grupper")}
			primaryAction={{
				content: t("groups.create", "Skapa ny grupp"),
				url: "/admin/groups/create",
			}}
			defaultFilters={
				[
					// {
					// 	key: "enabled",
					// 	value: "1",
					// },
				]
			}
			onClick={(item) => {
				history.push(`/admin/groups/${item.id}`, {
					data: item,
				});
			}}
			// filters={filters}
			resourceUrl={`/api/groups.json`}
			resourceName={{ singular: t("common.user", "Grupp"), plural: t("common.groups", "Grupper") }}
			filterKey={"groups"}
			resourceHandle={"groups"}
			columns={[
				{
					handle: "",
					label: "",
					render: renderItem,
				},
			]}
			// promotedBulkActions={promotedBulkActions}
			// bulkActions={[]}
		/>
	);
};
export default withTranslation(["groups", "common"])(GroupIndex);
