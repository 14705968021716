import { Icon, Tooltip } from "@shopify/polaris";
import { AppsMajor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { getColorFromIndex } from "src/js/Colors";
import BarGraph from "src/js/components/Graphs/BarGraph";
import OptionList from "src/js/components/OptionList/OptionList";
import { deepMergeReplaceKey, getLocalStorage, setLocalStorage } from "src/js/Utilities";
// import Select from "src/js/components/select/Select";
import numberFormatter from "src/js/Utilities/numberFormatter";

const SalesValueBarGraph = ({ data, loading, t }) => {
	const ref = useRef<any>(null);

	const { labels = [], sections } = useMemo(() => {
		if (!data?.sales) return { labels: [], sections: {} };

		const { labels, ...sections } = data.sales;

		return {
			labels,
			sections,
		};
	}, [data?.sales]);

	const propertyOptions = useMemo(() => {
		if (!sections) return [];
		const allOptions = Object.entries(sections).flatMap(([key, col]: any) => ({
			...(col || {}),
			label: (
				<p style={{ display: "flex", gap: "0.3125rem" }}>
					{col.label}
					{col.grouped && (
						<Tooltip content={t("sales.dashboard.terms.grouped", "Grupperad")}>
							<Icon source={AppsMajor} />
						</Tooltip>
					)}
				</p>
			),
			value: key,
		}));

		const uniqueOptions = [...new Map(allOptions.map((option) => [option.value, option])).values()];

		return uniqueOptions.sort((a, b: any) => (b.grouped ? 1 : -1));
	}, [sections, t]);

	const getDefaultProperties = useCallback(() => {
		return propertyOptions
			?.filter((opt) => opt.default)
			?.map((opt) => opt.value)
			.filter(Boolean);
	}, [propertyOptions]);

	const [properties, setProperties] = useState(getLocalStorage("dashboards")?.sales?.value_bar_graph?.properties || getDefaultProperties);

	useEffect(() => {
		if (propertyOptions?.length > 0 && !properties?.length) {
			setProperties(getDefaultProperties);
		}
	}, [propertyOptions, properties, getDefaultProperties]);

	const series =
		properties.map((property) => {
			return {
				name: sections?.[property]?.label || "",
				data: sections?.[property]?.data || [],
				color: getColorFromIndex(propertyOptions.findIndex((opt) => opt.value === property)),
			};
		}) || [];

	const handleChangeProperties = (value) => {
		setProperties(value);

		setLocalStorage("dashboards", (c) =>
			deepMergeReplaceKey(c || {}, {
				sales: {
					value_bar_graph: {
						properties: value,
					},
				},
			})
		);
	};

	const [pointWidth, setPointWidth] = useState(20);

	useLayoutEffect(() => {
		if (!ref.current) return;
		const width = ref.current.clientWidth;
		const pointWidth = Math.max(
			Math.min(
				width / ((labels.length || 0) * 2), // space
				20
			),
			2
		);

		setPointWidth(pointWidth);
	}, [labels]);

	return (
		<div ref={ref}>
			{/* <Select label="" labelHidden options={typeOptions} value={String(type)} onChange={handleChangeType} /> */}
			<OptionList
				preferredAlignment="left"
				style={{ marginBottom: "0.6250rem" }}
				popover
				allowMultiple
				options={propertyOptions}
				selected={properties}
				onChange={handleChangeProperties}
			/>

			<BarGraph
				// key={`invoiceGraph-${selectedYear}`}
				error={data?.sales?.error}
				stacked
				data={{
					series,
					labels,
				}}
				loading={!!loading}
				formatter={(value) => {
					return numberFormatter({
						value,
						currency: "SEK",
						maximumFractionDigits: 0,
					});
				}}
				showLegends
				pointWidth={pointWidth}
				settings={{
					stacked: false,
				}}
			/>
		</div>
	);
};
export default withTranslation(["sales"])(SalesValueBarGraph);
