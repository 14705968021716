import { Checkbox, FormLayout, Layout, LegacyCard } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "src/js/components/modal";
import TextField from "src/js/components/TextField";

const DepartmentSetting = ({ department, onSave, onRemove }) => {
	const { t } = useTranslation(["ticket", "common"]);
	const [form, setForm] = useState(department || {});
	const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);

	useEffect(() => {
		if (department) setForm(department);
	}, [department]);

	const handleSave = async (data = form) => {
		await onSave(data);
	};

	const handleRemove = async () => {
		await onRemove(department);
	};

	const getChangeHandler =
		(key, autoSave = true) =>
		(value) => {
			const newForm = { ...form, [key]: value };
			setForm(newForm);

			if (autoSave) handleSave(newForm);
		};

	return (
		<>
			<Layout.AnnotatedSection
				title={department.title}
				// description={this.props.t("ticket.departments.section.description", "Inställningar för denna kö")}
			>
				<LegacyCard
					actions={[
						{
							content: t("common.remove", "Ta bort") as string,
							onAction: () => {
								setRemoveModalIsOpen(true);
							},
						},
					]}
				>
					<LegacyCard.Section>
						<FormLayout>
							<TextField
								onBlur={() => handleSave(form)}
								onChange={getChangeHandler("title", false)}
								value={form.title + ""}
								label={t("ticket.departments.title.label", "Titel")}
							/>

							<Checkbox
								onChange={getChangeHandler("auto_remind_pending")}
								checked={form.auto_remind_pending}
								label={t("ticket.fields.auto_remind_pending.label", "Skicka påminnelse till ärenden som väntar på svar")}
							/>

							{form.auto_remind_pending && (
								<TextField
									onBlur={() => handleSave(form)}
									type="number"
									onChange={getChangeHandler("auto_remind_pending_days", false)}
									value={form.auto_remind_pending_days + ""}
									label={t("ticket.departments.auto_remind_pending_days.label", "Dagar sedan senast aktivitet")}
								/>
							)}

							{form.auto_remind_pending && (
								<TextField
									onBlur={() => handleSave(form)}
									onChange={getChangeHandler("auto_remind_pending_message", false)}
									value={form.auto_remind_pending_message}
									multiline
									label={t("ticket.departments.auto_remind_pending_message.label", "Meddelande")}
								/>
							)}
						</FormLayout>
					</LegacyCard.Section>
					<LegacyCard.Section>
						<FormLayout>
							<Checkbox
								onChange={getChangeHandler("auto_close_pending")}
								checked={form.auto_close_pending}
								label={t("ticket.departments.auto_close_pending.label", "Avsluta automatiskt ärenden som väntar på svar")}
							/>

							{form.auto_close_pending && (
								<TextField
									onBlur={() => handleSave(form)}
									type="number"
									onChange={getChangeHandler("auto_close_pending_days", false)}
									value={form.auto_close_pending_days + ""}
									label={t("ticket.departments.auto_close_pending_days.label", "Dagar sedan senast aktivitet")}
								/>
							)}

							{form.auto_close_pending && (
								<TextField
									onBlur={() => handleSave(form)}
									onChange={getChangeHandler("auto_close_pending_message", false)}
									value={form.auto_close_pending_message}
									multiline
									label={t("ticket.departments.auto_close_pending_message.label", "Meddelande")}
								/>
							)}
						</FormLayout>
					</LegacyCard.Section>
				</LegacyCard>
			</Layout.AnnotatedSection>

			<Modal
				title={t("ticket.departments.remove.title", "Ta bort kö")}
				open={removeModalIsOpen}
				onClose={() => setRemoveModalIsOpen(false)}
				primaryAction={{
					content: t("ticket.departments.remove.confirm", "Ta bort") as string,
					onAction: handleRemove,
					destructive: true,
				}}
			>
				{t("ticket.departments.remove.confirmation", "Är du säker på att du vill ta bort denna kö?")}
			</Modal>
		</>
	);
};
export default DepartmentSetting;
