import React from "react";
import styled from "styled-components";
import API from "src/js/API";
import { WithTranslation, withTranslation } from "react-i18next";
import IndexTable from "src/js/components/IndexTable";
import Toast from "src/js/components/Toast";

type ResourceListManagerProps = {
	resourceUrl?: string;
	resourceHandle: string;
	addRemoveUrl: string;
	params?: any;
	history: any;
	resourceName: {
		singular: string;
		plural: string;
	};
	filters?: any[];
	onClick: (item: any) => void;
	renderItem: (item: any) => any;
	appliedFilters?: any[];
	promotedBulkActions?: any[];
	filterKey?: string;
} & WithTranslation;

const ResourceListManager = ({
	resourceUrl,
	params = {},
	resourceHandle = "",
	filterKey,
	addRemoveUrl,
	resourceName,
	promotedBulkActions: propsPromotedBulkActions,
	filters = [],
	renderItem,
	onClick,
	appliedFilters,
	history,
	t,
}: ResourceListManagerProps) => {
	const ref = React.useRef<any>(null);

	const handleRemove = async () => {
		try {
			const ids = ref.current?.getSelectedItems();

			await API.delete(addRemoveUrl, { data: { ids } });
			Toast.success(t("resources.responses.removed", "Borttagen"));
			ref.current?.reload();

			return true;
		} catch (e) {
			Toast.error(e);
		}
	};

	const handleAdd = async () => {
		try {
			const ids = ref.current?.getSelectedItems();

			await API.post(addRemoveUrl, { ids });
			Toast.success(t("resources.responses.added", "Tillagd"));
			ref.current?.reload();

			return true;
		} catch (e) {
			Toast.error(e);
		}
	};

	const defaultPromotedBulkActions = [
		{
			content: t("resources.add", "Lägg till") as unknown as string,
			onAction: handleAdd,
		},
		{
			content: t("resources.delete", "Ta bort") as unknown as string,
			onAction: handleRemove,
		},
	];

	const promotedBulkActions = propsPromotedBulkActions !== undefined ? propsPromotedBulkActions : defaultPromotedBulkActions;

	return (
		<Wrapper>
			<IndexTable
				history={history}
				defaultFilters={appliedFilters}
				ref={ref}
				params={params}
				onClick={onClick}
				filters={filters}
				resourceUrl={resourceUrl || `/api/${resourceHandle}.json`}
				resourceName={resourceName}
				resourceHandle={resourceHandle}
				filterKey={filterKey}
				columns={[
					{
						handle: "",
						label: "",
						render: renderItem,
					},
				]}
				promotedBulkActions={promotedBulkActions}
				bulkActions={[]}
			/>
		</Wrapper>
	);
};

export default withTranslation(["resources", "common"])(ResourceListManager);

const Wrapper = styled.div``;
