import { FormLayout } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import API from "src/js/API";
import Modal from "src/js/components/modal";
import TextField from "src/js/components/TextField";
import Toast from "src/js/components/Toast";
import { store } from "src/js/store";

const NewDepartmentModal = ({ open, onClose, onSuccess }) => {
	const { t } = useTranslation(["ticket", "common"]);
	const defaultForm = useMemo(() => {
		return {
			title: "",
			department: "",
		};
	}, []);

	const [form, setForm] = useState<any>(defaultForm);

	useEffect(() => {
		if (open) {
			setForm(defaultForm);
		}
	}, [open, defaultForm]);

	const getChangeHandler = (key) => (value) => {
		const newForm = { ...form, [key]: value };
		setForm(newForm);
	};

	const handleSave = async () => {
		try {
			const res = await API.post("/api/ticket_departments.json", form);

			Toast.success(t("ticket.responses.queue_created", "Skapade kö"));
			onSuccess(res.data.ticket_department);
		} catch (error) {
			Toast.error(error);
		}
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			title={t("ticket.departments.new", "Ny kö")}
			primaryAction={{
				content: t("common.save", "Spara") as string,
				onAction: handleSave,
			}}
		>
			<FormLayout>
				<TextField label={t("ticket.fields.title.label", "Namn")} value={form.title} onChange={getChangeHandler("title")} />
				<TextField
					label={t("ticket.fields.department.label", "Mailbox")}
					prefix={store.getState().account.handle + "."}
					suffix="@sellfinity.com"
					helpText={t(
						"ticket.fields.title.helptext",
						"Du kan vidarebefordra mail från support@dittforetag.se till {{handle}}{{department}}@sellfinity.com",
						{
							handle: store.getState().account.handle,
							department: form.department || "",
						}
					)}
					value={form.department}
					onChange={getChangeHandler("department")}
				/>
			</FormLayout>
		</Modal>
	);
};
export default NewDepartmentModal;
