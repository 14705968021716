import axios from "axios";
import { toastr as t, actions } from "react-redux-toastr";
import { ERROR_MESSAGES } from "src/constants/errors";
import Spinner from "./Spinner";

// eslint-disable-next-line import/prefer-default-export
export class toastr {
	static remove(id) {
		actions.remove(id);
	}

	static removeByType(type) {
		t.removeByType(type);
	}

	static clean() {
		t.clean();
	}

	static success(message) {
		t.success("Klar", message, { disableCloseButtonFocus: true });
	}

	static message(message) {
		t.light("Meddelande", message, { disableCloseButtonFocus: true });
	}

	static loading(message, title = "", options = {}) {
		t.info(message, title, {
			disableCloseButtonFocus: true,
			timeOut: 60000,
			progressBar: false,
			className: "updating-toast",
			icon: <Spinner size="small" />,
			removeOnHover: false,
			...options,
		});
	}

	static info(message, title = "Info", options = {}) {
		t.light(title, message, { disableCloseButtonFocus: true, icon: "info", stauts: "info", ...options });
	}

	static warning(message) {
		t.warning("Varning", message, { disableCloseButtonFocus: true });
	}

	static error(error) {
		if (axios.isCancel(error) || error?.code === "ECONNABORTED") return;
		console.error("Error:", error);

		if (
			[
				ERROR_MESSAGES.FORTNOX.COULD_NOT_LOGIN,
				ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN,
				ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN_OR_INVALID_FOR_THE_CLIENT,
				ERROR_MESSAGES.FORTNOX.INVALID_REFRESH_TOKEN,
			]
				?.flatMap((err) => [err.toLowerCase(), `fortnox: ${err.toLowerCase()}`])
				.includes(error?.response?.data?.message?.toLowerCase())
		) {
			const event = new CustomEvent("fortnoxReconnect", { detail: { error: error.response.data } });
			window.dispatchEvent(event);
			return;
		}

		let message;

		if (typeof error == "object") {
			if ("response" in error && error.response) {
				if (error.response.status == 403) {
					const event = new CustomEvent("403", {
						detail: {
							message: error.response.data.message,
							data: error.response.data,
							config: JSON.parse(
								JSON.stringify({
									url: error.config.url,
									method: error.config.method,
									headers: error.config.headers,
									data: error.config.data,
									params: error.config.params,
								})
							),
						},
					});
					window.dispatchEvent(event);
					return;
				}
				if (error.response.status == 401) {
					window.location.href = "/admin/";
					return;
				} else {
					if ("message" in error.response.data) {
						message = error.response.data.message;
					} else if ("error" in error.response.data) {
						if (typeof error.response.data.error == "object") {
							if ("message" in error.response.data.error) {
								message = error.response.data.error.message;
							}
						} else {
							message = error.response.data.error;
						}
					}

					if (error.response.status == 302) {
						// This doesnt work anymore because suddenly axios/browser follow redirects
						window.location.href = message;
						return;
					}
				}
			} else if (error?.message) {
				message = error?.message;
			} else {
				console.error("-No response or response is null in error", error);
			}
		} else {
			message = error;
		}
		console.error("Error message:", message);
		console.error("Error:", error);
		t.error("Något blev fel!", message, { disableCloseButtonFocus: true });
		return message;
	}
}

export const getErrorMessage = (error) => {
	return error?.response?.data?.message || error?.response?.data?.error?.message || error?.response?.data?.error || error?.message || error;
};
