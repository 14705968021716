import { Avatar } from "@shopify/polaris";
import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import Badge from "src/js/components/Badge";
import ItemCard from "src/js/components/ItemCard";
import TargetContext from "../Context/TargetContext";
import API from "src/js/API";
import IndexTable from "src/js/components/IndexTable";
import Toast from "src/js/components/Toast";

const TargetGroups = ({ t }) => {
	const { id, history, refreshTarget } = useContext(TargetContext);
	const ref = React.useRef<any>(null);

	const renderItem = (item: any) => {
		return (
			<ItemCard
				media={<Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />}
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
						{item.name}
						{item.connected ? (
							<Badge color={Colors.green}>{t("common.connected", "Ansluten")}</Badge>
						) : (
							<Badge color={Colors.grey}>{t("common.notConnected", "Ej Ansluten")}</Badge>
						)}
					</div>
				}
				leftColumn={[
					{
						label: t("groups.field.users_display", "Användare"),
						value: item.users_display,
					},
				]}
				rightColumn={[]}
			/>
		);
	};

	const handleRemove = async () => {
		try {
			const ids = ref.current?.getSelectedItems();

			await API.delete(`/api/targets/${id}/target_connections.json`, { data: { group_ids: ids } });
			Toast.success(t("target.groups.responses.removed", "Borttagen"));
			ref.current?.reload();
			refreshTarget();
			return true;
		} catch (error) {
			Toast.error(error);
		}
	};

	const handleAdd = async () => {
		try {
			const ids = ref.current?.getSelectedItems();

			await API.post(`/api/targets/${id}/target_connections.json`, { group_ids: ids });
			Toast.success(t("target.groups.responses.added", "Tillagd"));
			ref.current?.reload();
			refreshTarget();
			return true;
		} catch (error) {
			Toast.error(error);
		}
	};

	const promotedBulkActions = [
		{
			content: t("target.groups.add", "Lägg till") as unknown as string,
			onAction: handleAdd,
		},
		{
			content: t("target.groups.delete", "Ta bort") as unknown as string,
			onAction: handleRemove,
		},
	];

	return (
		<IndexTable
			history={history}
			// useDefaultRenderKey
			defaultFilters={[
				{
					key: "enabled",
					value: "1",
				},
			]}
			ref={ref}
			params={{
				connected_target_id: id,
			}}
			onClick={(item) => {
				history.push(`/admin/groups/${item.id}`, {
					data: item,
				});
			}}
			// filters={filters}
			resourceUrl={`/api/groups.json`}
			resourceName={{ singular: t("common.user", "Grupp"), plural: t("common.groups", "Grupper") }}
			filterKey={"groups"}
			resourceHandle={"groups"}
			columns={[
				{
					handle: "",
					label: "",
					render: renderItem,
				},
			]}
			promotedBulkActions={promotedBulkActions}
			// bulkActions={[]}
		/>
	);
};
export default withTranslation(["targets", "common"])(TargetGroups);
