import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { Button, LegacyCard, Collapsible, EmptyState, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { store } from "../../store";
import LeaderboardSmallRow from "./LeaderboardSmallRow";
import Toast from "src/js/components/Toast";
import BoardHelper from "../Workspaces/BoardHelper";
import NewDatePicker from "../../components/NewDatePicker";

moment.locale("sv");

class LeaderboardSmall extends Component {
	constructor(props) {
		super(props);
		this.state = {
			view: "won",
			board: props.board,
			from: moment().subtract(1, "month").format("YYYY-MM-DD"),
			to: moment().format("YYYY-MM-DD"),
			// to: moment().endOf("month").format("YYYY-MM-DD"),
			rows: props.row,
			unfinishedTasks: [],
			acitivityComments: [],
			meetingDone: [],
			meetingBooked: [],
			meetingOpen: [],
			selectColumnValue: "value",
			loading: false,
		};
		this.source = axios.CancelToken.source();
	}

	refresh() {
		this.initialFetch();
	}

	componentDidMount() {
		this.initialFetch();
	}

	componentWillUnmount() {
		BoardHelper.cancelRequests();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.rows) {
			this.setState({ rows: props.rows });
		}
		if (props.board) {
			this.setState({ board: props.board });
		}
	}

	initialFetch() {
		this.setState({ loading: true });
		const requests = [
			this.fetchUnfinishedTasks(),
			this.fetchMeetings("done"),
			this.fetchMeetings("booked"),
			this.fetchMeetings("open"),
			this.fetchActivityComments(),
		];

		if (this.props.rows === undefined) {
			requests.push(this.fetchRows());
		}

		Promise.allSettled(requests)
			.then(() => {
				// console.debug("all fetches done;");
				this.setState({ loading: false });

				if (this.props.onFinishedLoading) {
					this.props.onFinishedLoading();
				}
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				if (!axios.isCancel(error) && error?.code !== "ECONNABORTED") {
					console.error("error:", error);
				}
				this.setState({ loading: false });
			});
	}

	fetchRows() {
		return new Promise((resolve) => {
			BoardHelper.fetchRows(
				[store.getState().account.sales_board_id],
				null,
				(res) => {
					// console.debug("Fetching rows done");
					if (this.props.board === undefined) {
						API.get("/api/boards/" + store.getState().account.sales_board_id + ".json", { cancelToken: this.source.token, params: {} }).then(
							(result) => {
								// console.debug("Fetching board done");
								this.setState((c) => ({ board: result.data.board, rows: BoardHelper.formatSalesBoardRows(res, result.data.board) }));
								resolve(true);
							}
						);
					} else {
						this.setState((c) => ({ rows: BoardHelper.formatSalesBoardRows(res, c.board) }));
						resolve(true);
					}
				},
				{ also_archived: 1 },
				true
			);
		});
	}

	fetchUnfinishedTasks() {
		const params = {
			dates_from: this.state.from,
			dates_to: this.state.to,
			deadline_statuses: 0,
			not_calendars: 1,
			assigned_user_role: "ROLE_WORKSPACE_" + store.getState().account.sales_workspace_id,
		};

		API.get("/api/board_rows/search/per_user.json", { cancelToken: this.source.token, params })
			.then((result) => {
				if (result.data.error) {
					console.error("error: ", result.data.error);
					return;
				}
				this.setState({ unfinishedTasks: result.data.users });
				// return this.formatMeetingsData(result.data);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					// console.debug("Request canceled");
					return;
				}
				Toast.error(error);
			});
	}

	fetchActivityComments() {
		const params = {
			created_from: this.state.from,
			created_to: this.state.to,
		};
		API.get("/api/account/comment_stats.json", { cancelToken: this.source.token, params })
			.then((res) => {
				this.setState({ acitivityComments: res.data });
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					// console.debug("Request canceled");
					return;
				}
				Toast.error(error);
			});
	}

	getTypeParam(type) {
		switch (type) {
			case "booked":
				return {
					created_from: this.state.from,
					created_to: this.state.to,
				};
			case "done":
				return {
					deadline_statuses: 1,
					dates_from: this.state.from,
					dates_to: this.state.to,
				};
			case "open":
				return {
					deadline_statuses: 0,
					dates_from: this.state.from,
					dates_to: this.state.to,
				};
			default:
				return null;
		}
	}

	fetchMeetings(type) {
		const meetingType = store.getState().user.calendar_board && store.getState().user.calendar_board.categories.find((cate) => cate.title === "Möte");
		const params = Object.assign(
			{
				category_id: meetingType && meetingType.id,
				all_calendars: true,
				assigned_user_role: "ROLE_WORKSPACE_" + store.getState().account.sales_workspace_id,
			},
			this.getTypeParam(type)
		);

		return API.get("/api/board_rows/search/per_user.json", { cancelToken: this.source.token, params })
			.then((result) => {
				if (result.data.error) {
					console.error("error: ", result.data.error);
					return;
				}

				this.setState({ [`meeting${type.capitalize()}`]: result.data.users });
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					// console.debug("Request canceled");
					return;
				}
				Toast.error(error);
			});
	}

	getWonDeals() {
		return (this.state.rows || []).filter((row) => {
			const won = row.status && this.state.board?.options?.stats?.won?.status_values?.includes(row.status?.id);

			const date = !this.state.from || (row.date && moment(row.date).isBetween(moment(this.state.from), moment(this.state.to).add(1, "days")));
			const person = !this.state.person || String(row.person) === String(this.state.person);
			const group = !this.state.office || row.person_group_id == this.state.office;

			return date && person && group && won;
		});
	}

	getOpenDeals() {
		return (this.state.rows || []).filter((row) => {
			const date = !row.date;
			const person = !this.state.person || String(row.person) === String(this.state.person);
			const group = !this.state.office || row.person_group_id == this.state.office;

			return date && person && group;
		});
	}

	getUserRows() {
		if ((this.state.loading || this.props.loading) && !this.state.rows) return Array.from(Array(5));
		if (this.state.view === "unfinishedtasks") {
			return this.state.unfinishedTasks.map((user) => ({
				user: store.getState().users.find((u) => u.id == user.user_id),
				values: [user.count],
				types: ["count"],
			}));
		}
		if (this.state.view === "activity") {
			return this.state.acitivityComments.map((row) => ({
				user: store.getState().users.find((u) => u.id == row.user_id),
				values: [row.count],
				types: ["count"],
			}));
		}

		if (this.state.view === "meetingOpen") {
			return this.state.meetingOpen.map((row) => ({
				user: store.getState().users.find((u) => u.id == row.user_id),
				values: [row.count],
				types: ["count"],
			}));
		}

		if (this.state.view === "meetingDone") {
			return this.state.meetingDone.map((row) => ({
				user: store.getState().users.find((u) => u.id == row.user_id),
				values: [row.count],
				types: ["count"],
			}));
		}

		if (this.state.view === "meetingBooked") {
			return this.state.meetingBooked.map((row) => ({
				user: store.getState().users.find((u) => u.id == row.user_id),
				values: [row.count],
				types: ["count"],
			}));
		}

		const getSecondColumnValue = (row) => {
			switch (this.state.selectColumnValue) {
				case "tb":
					return row.tb;
				case "support_tb":
					return row.support_tb;
				case "value":
				default:
					return row.value;
			}
		};

		const userRows = (() => {
			switch (this.state.view) {
				case "open":
					return this.getOpenDeals();
				case "won":
				default:
					return this.getWonDeals();
			}
		})();

		return userRows.reduce((acc, row) => {
			if (acc[row.person]) {
				const curr = acc[row.person];
				const [count, secondColumnValue] = curr.values || [];
				acc[row.person] = Object.assign(curr, {
					values: [count + 1, secondColumnValue + getSecondColumnValue(row)],
				});
			} else {
				acc[row.person] = {
					user: store.getState().users.find((u) => u.id == row.person),
					user_id: row.person || 0,
					values: [1, getSecondColumnValue(row)],
					types: ["count", "money"],
					board_id: row.board_id,
					status: row.status,
					statusColumnId: row.statusColumnId,
					dateColumnId: row.dateColumnId,
				};
			}

			return acc;
		}, {});
	}

	toggleOpen() {
		this.setState((c) => ({ open: !c.open }));
	}

	openBoardLink(row) {
		if (!["open", "won"].includes(this.state.view)) return null;
		const url = `/admin/workspaces/none/boards/${row.board_id}#${JSON.stringify(
			[
				{
					key: `person-${row.user_id}`,
					board_id: row.board_id,
					column_id: "person",
					value: [row.user_id],
				},
				this.state.view === "won" && {
					key: `${row.statusColumnId}-${row.user_id}`,
					board_id: row.board_id,
					column_id: row.statusColumnId,
					value: [row.status && row.status.id],
				},
				this.state.view === "won"
					? {
							key: `${row.dateColumnId}-${row.user_id}`,
							board_id: row.board_id,
							column_id: row.dateColumnId,
							operator: "between",
							value: this.state.from,
							value2: this.state.to,
					  }
					: {
							key: `${row.dateColumnId}-${row.user_id}`,
							board_id: row.board_id,
							column_id: row.dateColumnId,
							operator: "equals",
							value: null,
					  },
			].filter((i) => i)
		)}`;

		this.props.history.push(url);

		return url;
	}

	render() {
		const userRows = Object.values(this.getUserRows() || []).sort((a, b) => {
			switch (this.state.view) {
				case "activity":
				case "unfinishedtasks":
				case "meetingBooked":
				case "meetingOpen":
				case "meetingDone":
					return b.values[0] - a.values[0];
				case "won":
				case "open":
				default:
					return b.values[1] - a.values[1];
			}
		});

		const data = userRows.slice(0, this.props.count || 5);
		const dataShowMore = userRows.slice(this.props.count || 5);

		const headers = (() => {
			switch (this.state.view) {
				case "activity":
				case "unfinishedtasks":
				case "meetingBooked":
				case "meetingOpen":
				case "meetingDone":
					return [{ title: this.props.t("leaderboard.columns.count.label", "Antal") }];
				case "won":
				case "open":
				default:
					return [
						{ title: this.props.t("leaderboard.columns.sales_count.label", "Antal Affärer") },
						{
							comp: (
								<Select
									value={this.state.selectColumnValue}
									options={[
										{ label: this.props.t("leaderboard.columns.value.label", "Omsättning"), value: "value" },
										{ label: this.props.t("leaderboard.columns.tb.label", "TB"), value: "tb" },
										{ label: this.props.t("leaderboard.columns.support_tb.label", "Support TB"), value: "support_tb" },
									]}
									onChange={(selectColumnValue) => {
										this.setState({ selectColumnValue });
									}}
								/>
							),
						},
					];
			}
		})();

		const loading = (this.props.loading || this.state.loading) && !["won", "open"].includes(this.state.view);

		return (
			<div className="leaderboard_small">
				<h1 style={{ marginBottom: 20 }}>{this.props.title || this.props.t("leaderboard.title", "Leaderboard")}</h1>
				<LegacyCard>
					<LegacyCard.Section>
						<div className="leaderboard_small_header">
							<div className="plain-select">
								<Select
									value={this.state.view}
									options={[
										{
											label: this.props.t("leaderboard.filters.won.label", "Vunna affärer"),
											value: "won",
										},
										{
											label: this.props.t("leaderboard.filters.open.label", "Öppna affärer"),
											value: "open",
										},
										{
											label: this.props.t("leaderboard.filters.unfinished_tasks.label", "Ej avslutade aktiviteter"),
											value: "unfinishedtasks",
										},
										{
											label: this.props.t("leaderboard.filters.activity.label", "Aktivitet"),
											value: "activity",
										},
										{
											label: this.props.t("leaderboard.filters.meeting_booked.label", "Bokade möten"),
											value: "meetingBooked",
										},
										{
											label: this.props.t("leaderboard.filters.meeting_open.label", "Ej avslutade möten"),
											value: "meetingOpen",
										},
										{
											label: this.props.t("leaderboard.filters.meeting_done.label", "Avslutade möten"),
											value: "meetingDone",
										},
									]}
									onChange={(view) => {
										this.setState({ view });
									}}
								/>
							</div>
							<div className="plain-select">
								<div style={{ display: "flex", gap: "0.6250rem" }}>
									<NewDatePicker
										optionalTime={false}
										inheritActivator
										value={this.state.from}
										onClose={(from) => {
											this.setState({ from }, this.refresh.bind(this));
										}}
									/>

									<NewDatePicker
										optionalTime={false}
										inheritActivator
										value={this.state.to}
										onClose={(to) => {
											this.setState({ to: moment(to).isAfter(moment()) ? moment().format("YYYY-MM-DD") : to }, this.refresh.bind(this));
										}}
									/>
								</div>

								{/* <Select
									disabled={this.state.view === "open" || this.props.loading || this.state.loading}
									value={String(this.state.month)}
									options={Array.from(Array(parseInt(moment().locale("sv").format("MM")))).map((_, index) => ({
										label: moment().locale("sv").set("month", index).format("MMMM")?.caxiostalize(),
										value: String(index),
									}))}
									onChange={(month) => {
										const from = moment().set("month", month).startOf("month").format("YYYY-MM-DD");
										const to = moment().set("month", month).endOf("month").format("YYYY-MM-DD");

										this.setState(
											{ month: String(month), from, to: moment(to).isAfter(moment()) ? moment().format("YYYY-MM-DD") : to },
											this.refresh.bind(this)
										);
									}}
								/> */}
							</div>
						</div>

						<table className="leaderboard_small_table">
							<thead>
								<tr className="leaderboard_small_table_header">
									<th aria-label="avatar" style={{ minWidth: "23px" }} />
									<th>{this.props.t("common.terms.name", "Namn")}</th>
									{headers.map(({ title, comp }, index) => (
										<th className="plain-select" key={index}>
											{comp || title}
										</th>
									))}
								</tr>
							</thead>
							<tbody style={{ opacity: loading ? 0.5 : 1 }}>
								{data &&
									Array.isArray(data) &&
									!!data.length &&
									data.map((row, index) => (
										<React.Fragment key={index}>
											<LeaderboardSmallRow
												loading={loading}
												key={index}
												index={index}
												className={`index-${index}`}
												row={row}
												onClick={row && this.openBoardLink.bind(this, row)}
											/>
											<tr className="spacer" />
										</React.Fragment>
									))}
							</tbody>
						</table>
						<Collapsible open={this.state.open}>
							{this.props.showMore && dataShowMore && Array.isArray(dataShowMore) && (
								<table className="leaderboard_small_table">
									<thead style={{ display: "none" }}>
										<tr className="leaderboard_small_table_header">
											<th aria-label="avatar" style={{ minWidth: "23px" }} />
											<th>{this.props.t("common.terms.name", "Namn")}</th>
											{headers.map(({ title, comp }, index) => (
												<th className="plain-select" key={index}>
													{comp || title}
												</th>
											))}
										</tr>
									</thead>
									<tbody style={{ opacity: loading ? 0.5 : 1 }}>
										{dataShowMore.map((row, index) => (
											<React.Fragment key={index}>
												<LeaderboardSmallRow
													onClick={!row && this.openBoardLink.bind(this, row)}
													loading={loading}
													key={data.length + index}
													index={data.length + index}
													className={`index-${data.length + index}`}
													row={row}
												/>
												<tr className="spacer" />
											</React.Fragment>
										))}
									</tbody>
								</table>
							)}
						</Collapsible>
						{(!data || !Array.isArray(data) || !data.length) && !this.state.loading && !this.props.loading && (
							<EmptyState heading={this.props.t("common.empty.data", "Ingen data att visa")} image="/assets/images/empty_state/NoResults.png" />
						)}
						{dataShowMore && this.props.showMore && !!dataShowMore.length && (
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Button onClick={this.toggleOpen.bind(this)} plain>
									{this.props.t("common.terms.show", "Visa")}{" "}
									{this.state.open ? this.props.t("common.terms.less", "mindre") : this.props.t("common.terms.more", "mer")}
								</Button>
							</div>
						)}
					</LegacyCard.Section>
				</LegacyCard>
			</div>
		);
	}
}
export default withTranslation(["leaderboard", "common"], { withRef: true })(LeaderboardSmall);
