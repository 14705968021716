/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from "react";
import { Page as PolarisPage } from "@shopify/polaris";
import type { MenuActionDescriptor as PolarisMenuActionDescriptor, PageProps as PolarisPageProps } from "@shopify/polaris";
// import { withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import ActionsBar from "./ActionsBar";
import styled, { css } from "styled-components";
import { CardListWrapper } from "../CardList";
import Spinner from "../Spinner";
import SkeletonPage from "../Skeletons/Page";
import { checkUserRoles } from "src/js/Utilities";
import Button from "../Button";
import ActionButton from "./ActionButton";
import { useHistory } from "react-router-dom";
import { DefaultTFuncReturn } from "i18next";

export type MenuActionDescriptor = PolarisMenuActionDescriptor & {
	roles?: string[];
};

type ConfirmationType = {
	title?: string | null | DefaultTFuncReturn;
	content?: string | null | React.ReactNode | DefaultTFuncReturn | React.ReactElement;
	confirm?: string | null | DefaultTFuncReturn;
	cancel?: string | null | DefaultTFuncReturn;
};

export type SecondaryAction = MenuActionDescriptor & {
	confirmation?: ConfirmationType;

	isGroup?: boolean;
	enabled?: boolean;
	actions?: SecondaryAction[];
	roles?: string[];
};

type PageProps = Omit<PolarisPageProps, "title"> & {
	secondaryActions?: any;
	actionGroups?: PolarisPageProps["actionGroups"] | React.ReactNode[];
	title?: string | React.ReactNode | DefaultTFuncReturn;
	history?: any; // DEPRECTED
	loading?: boolean;
	skeleton?: boolean;
	inlinePrimaryAction?: PolarisPageProps["primaryAction"];
	primaryAction?:
		| (PolarisPageProps["primaryAction"] & {
				onClick?: () => any | Promise<any>;
				confirmation?: ConfirmationType;
		  })
		| null;
	flex?: boolean;
} & WithTranslation;

const Page = ({
	children,
	secondaryActions,
	actionGroups,
	primaryAction: propsPrimaryAction,
	title,
	backAction: propsBackAction,
	t,
	loading,
	titleMetadata,
	skeleton,
	inlinePrimaryAction,
	flex,
	...rest
}: PageProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [isLoadingPrimaryAction, setIsLoadingPrimaryAction] = React.useState(false);
	const history = useHistory();

	const handlePrimaryAction = async () => {
		const action = propsPrimaryAction as any;
		setIsLoadingPrimaryAction(true);
		try {
			if (React.isValidElement(propsPrimaryAction)) {
				await (propsPrimaryAction.props as any).onClick?.();
			} else {
				await action?.onAction();
			}
		} catch (error) {
			console.error("Page primaryAction error:", error);
		}
		setIsLoadingPrimaryAction(false);
	};
	const primaryActionProps = !propsPrimaryAction ? undefined : React.isValidElement(propsPrimaryAction) ? (
		React.cloneElement(propsPrimaryAction, {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			/* @ts-ignore */
			onClick: handlePrimaryAction,
			loading: isLoadingPrimaryAction || (propsPrimaryAction as any)?.loading,
		})
	) : (
		<ActionButton
			item={{
				...(propsPrimaryAction as any),
				primary: "primary" in (propsPrimaryAction as any) ? (propsPrimaryAction as any).primary : true,
				plain: "plain" in (propsPrimaryAction as any) ? (propsPrimaryAction as any).plain : false,
			}}
		/>
		// : { ...(propsPrimaryAction as any), onAction: handlePrimaryAction, loading: isLoadingPrimaryAction || (propsPrimaryAction as any)?.loading };
	);

	const backAction =
		propsBackAction ||
		(history && history?.length > 1 ? { content: t("common.actions.back", "Tillbaka") as string, onAction: () => history.goBack() } : undefined);

	if (loading && skeleton) return <SkeletonPage title={title as string} />;

	const primaryAction = checkUserRoles((primaryActionProps?.props || primaryActionProps)?.roles) ? primaryActionProps : undefined;

	return (
		<Wrapper ref={ref} flex={!!flex}>
			<PolarisPage
				title={title as string}
				primaryAction={primaryAction}
				backAction={backAction}
				titleMetadata={
					<>
						{titleMetadata}
						{!!loading && (
							<LoadingWrapper>
								<Spinner size={"small"} />
							</LoadingWrapper>
						)}
					</>
				}
				{...rest}
			>
				{inlinePrimaryAction ? (
					<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0.5rem" }}>
						<ActionsBar secondaryActions={secondaryActions} actionGroups={actionGroups} style={{ opacity: 0.75 }} />

						{React.isValidElement(inlinePrimaryAction) ? (
							inlinePrimaryAction
						) : (
							<Button primary {...((inlinePrimaryAction as any) || {})}>
								{(inlinePrimaryAction as any)?.content}
							</Button>
						)}
					</div>
				) : (
					<ActionsBar secondaryActions={secondaryActions} actionGroups={actionGroups} style={{ opacity: 0.75, marginBottom: "0.5rem" }} />
				)}

				{children}
			</PolarisPage>
		</Wrapper>
	);
};
export default withTranslation(["common"])(Page);

// export default (props: PageProps) => {
// 	return <Page {...props} />;
// };

const Wrapper = styled.div<{ flex?: boolean }>`
	&&& {
		/* ${CardListWrapper} {
			.Polaris-Page {
				padding: 0 !important;
			}
		} */
	}

	.Polaris-Button.Polaris-Button--primary {
		min-width: max-content;
	}

	${(props) =>
		props.flex &&
		css`
			height: 100%;

			.Polaris-Page {
				display: flex;
				flex-direction: column;
				height: 100%;
				margin: 0;

				& > div:nth-child(2) {
					flex: 1;
				}
			}
		`}
`;

const LoadingWrapper = styled.div`
	.Polaris-Spinner {
		display: flex;
	}
`;
