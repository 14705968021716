import { Layout } from "@shopify/polaris";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import API from "src/js/API";
import Page from "src/js/components/page";
import Toast from "src/js/components/Toast";
import useQuery from "src/js/hooks/useQuery";
import DepartmentSetting from "./DepartmentSetting";
import NewDepartmentModal from "./NewDepartmentModal";

const SupportSettings = () => {
	const { t } = useTranslation(["ticket", "common"]);
	const [form, setForm] = useState<any>();
	const [newDepartmentModalOpen, setNewDepartmentModalOpen] = useState(false);

	const queryClient = useQueryClient();

	const queryKey = ["servicedesk", "departments"];
	const { data: departments, isFetching: isLoading } = useQuery({
		queryKey,
		queryFn: async () => {
			try {
				const res = await API.get("/api/ticket_departments.json");
				return res.data.ticket_departments;
			} catch (e) {
				Toast.error(e);
			}
		},
	});

	useEffect(() => {
		if (departments) setForm(departments);
	}, [departments]);

	const handleSaveMutation = useMutation(
		async (department: any) => {
			const res = await API.put(`/api/ticket_departments/${department.id}.json`, department);
			return res.data.ticket_department;
		},
		{
			onSuccess: (department: any) => {
				const newDepartments = departments.map((d: any) => (d.id === department.id ? department : d));
				if (queryKey) queryClient.setQueryData(queryKey, newDepartments);
			},
		}
	);

	const handleSave = async (department) => {
		try {
			await handleSaveMutation.mutateAsync(department);
			Toast.success(t("ticket.departments.responses.updated", "Köinställning uppdaterades"));
		} catch (e) {
			Toast.error(e);
		}
	};

	const handleRemove = async (department) => {
		try {
			await API.delete(`/api/ticket_departments/${department.id}.json`);

			const newDepartments = departments.filter((d: any) => d.id !== department.id);
			if (queryKey) queryClient.setQueryData(queryKey, newDepartments);

			Toast.success(t("ticket.departments.responses.removed", "Köinställning togs bort"));
		} catch (e) {
			Toast.error(e);
		}
	};

	return (
		<Page
			loading={isLoading}
			title={t("ticket.departments.title", "Supportinställningar")}
			primaryAction={{
				content: t("ticket.departments.actions.new", "Ny kö") as string,
				onAction: () => {
					setNewDepartmentModalOpen(true);
				},
			}}
		>
			<Layout>
				{form?.map((department) => (
					<DepartmentSetting key={department?.id} department={department} onSave={handleSave} onRemove={handleRemove} />
				))}
			</Layout>

			<NewDepartmentModal
				open={newDepartmentModalOpen}
				onClose={() => setNewDepartmentModalOpen(false)}
				onSuccess={(department) => {
					setForm([department, ...form]);
					setNewDepartmentModalOpen(false);
				}}
			/>
		</Page>
	);
};
export default SupportSettings;
