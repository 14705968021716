import { Avatar } from "@shopify/polaris";
import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import Badge from "src/js/components/Badge";
import ItemCard from "src/js/components/ItemCard";
import TargetContext from "../Context/TargetContext";
import API from "src/js/API";
import IndexTable from "src/js/components/IndexTable";
import Toast from "src/js/components/Toast";

//!TODO: quick actions to mass edit all/selected users. Should we make this same as orderflow, with bulkactions that do not need to "save"?
//!TODO: Men hur fungerar det då med att redigera deras standard värde? Måste nog endån vara en save knapp.
const TargetUsers = ({ t }) => {
	const { id, history, refreshTarget } = useContext(TargetContext);
	const ref = React.useRef<any>(null);

	const renderItem = (item: any) => {
		return (
			<ItemCard
				media={<Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />}
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
						{item.name}
						{item.connected ? (
							<Badge color={Colors.green}>{t("common.connected", "Ansluten")}</Badge>
						) : (
							<Badge color={Colors.grey}>{t("common.notConnected", "Ej Ansluten")}</Badge>
						)}
					</div>
				}
				leftColumn={[
					{
						label: t("contacts.field.email", "Mailadress"),
						value: <a href={`mailto:${item.email}`}>{item.email}</a>,
					},
					{
						label: t("contacts.field.phone", "Telefon"),
						value: <a href={`tel:${item.phone}`}>{item.phone}</a>,
					},
				]}
				rightColumn={[]}
			/>
		);
	};

	const handleRemove = async () => {
		try {
			const ids = ref.current?.getSelectedItems();

			await API.delete(`/api/targets/${id}/target_connections.json`, { data: { user_ids: ids } });
			Toast.success(t("target.user.responses.removed", "Borttagen"));
			ref.current?.reload();
			refreshTarget();
			return true;
		} catch (error) {
			Toast.error(error);
		}
	};

	const handleAdd = async () => {
		try {
			const ids = ref.current?.getSelectedItems();

			await API.post(`/api/targets/${id}/target_connections.json`, { user_ids: ids });
			Toast.success(t("target.user.responses.added", "Tillagd"));
			ref.current?.reload();
			refreshTarget();
			return true;
		} catch (error) {
			Toast.error(error);
		}
	};

	const promotedBulkActions = [
		{
			content: t("target.user.add", "Lägg till") as unknown as string,
			onAction: handleAdd,
		},
		{
			content: t("target.user.delete", "Ta bort") as unknown as string,
			onAction: handleRemove,
		},
	];

	return (
		<IndexTable
			defaultFilters={[
				{
					key: "enabled",
					value: "1",
				},
			]}
			// useDefaultRenderKey
			history={history}
			ref={ref}
			params={{
				connected_target_id: id,
			}}
			onClick={(item) => {
				history.push(`/admin/users/${item.id}`, {
					data: item,
				});
			}}
			// filters={filters}
			resourceUrl={`/api/users.json`}
			resourceName={{ singular: t("common.user", "Användare"), plural: t("common.users", "Användare") }}
			resourceHandle={"users"}
			filterKey={"users"}
			columns={[
				{
					handle: "",
					label: "",
					render: renderItem,
				},
			]}
			promotedBulkActions={promotedBulkActions}
			// bulkActions={[]}
		/>
	);
};
export default withTranslation(["targets", "common"])(TargetUsers);
