import axios from "axios";
import nl2br from "react-nl2br";
import { ToastOptions, UpdateOptions, toast as reactToastify } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import API from "src/js/API";
import { ERROR_MESSAGES } from "src/constants/errors";

// Define the main Toast function that behaves as a default toast.
const Toast = (message, options: ToastOptions = {}) => {
	return reactToastify(message, {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		...options,
	});
};

// Attach static methods for specific types of toasts.
Toast.dark = (message, options: ToastOptions = {}) => {
	return reactToastify.dark(message, {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		...options,
	});
};

Toast.success = (message, options: ToastOptions = {}) => {
	return reactToastify.success(message, {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		...options,
	});
};

Toast.info = (message, options: ToastOptions = {}) => {
	return reactToastify.info(message, {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		...options,
	});
};

Toast.warning = (message, options: ToastOptions = {}) => {
	return reactToastify.warn(message, {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		...options,
	});
};
Toast.warn = Toast.warning;

Toast.error = (error, options: ToastOptions = {}) => {
	if (axios.isCancel(error) || error?.code === "ECONNABORTED" || error?.name === "CanceledError") return null;

	if (error?.response?.data?.code === 500 && error?.response?.data?.message === "Internal Server Error") {
		// const curl = generateCurlCommand(error);
		// API.post("/api/frontend_exception.json", {
		// 	error,
		// 	curl,
		// }).catch((error) => console.error("error", error));
	}

	if (
		[
			ERROR_MESSAGES.FORTNOX.COULD_NOT_LOGIN,
			ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN,
			ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN_OR_INVALID_FOR_THE_CLIENT,
			ERROR_MESSAGES.FORTNOX.INVALID_REFRESH_TOKEN,
		].includes(error?.response?.data?.message)
	) {
		const event = new CustomEvent("fortnoxReconnect", { detail: { error: error?.response?.data } });
		window.dispatchEvent(event);
		return;
	}

	if ([403, 406].includes(error?.response?.status)) {
		const event = new CustomEvent(error?.response?.status, {
			detail: {
				message: error.response.data.message,
				data: error.response.data,
				config: JSON.parse(
					JSON.stringify({
						url: error.config.url,
						method: error.config.method,
						headers: error.config.headers,
						data: error.config.data,
						params: error.config.params,
					})
				),
			},
		});
		window.dispatchEvent(event);
		return;
	}

	if (error?.response?.status == 401) {
		window.location.href = "/admin/";
		return;
	}

	console.error("Toast.error: ", error);

	const errorMessage = getErrorMessage(error);
	const toastId = createToastErrorId(error);
	const id = reactToastify.error(errorMessage, {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		pauseOnFocusLoss: false,
		draggable: true,
		toastId,
		...options,
	});

	return id;
};

Toast.loading = (message, options: ToastOptions = {}) => {
	return reactToastify.loading(message, {
		position: "bottom-right",
		autoClose: false,
		hideProgressBar: true,
		closeOnClick: false,
		draggable: true,
		...options,
	});
};

Toast.dismiss = (toastId?: any) => {
	return reactToastify.dismiss(toastId);
};

type CustomUpdateOptions = Omit<UpdateOptions, "render"> & {
	render?: any;
};

Toast.update = (toastId, options: CustomUpdateOptions = {}) => {
	if (options?.type && ["success", "error"].includes(options?.type)) {
		if (options?.type === "error" && typeof options?.render !== "string") {
			options.render = getErrorMessage(options?.render);
		} else if (typeof options?.render !== "string") {
			options.render = options?.render?.toString?.() || "Unknown error";
		}

		options = {
			...options,
			isLoading: false,
			hideProgressBar: false,
			autoClose: 5000,
		};
	}
	return reactToastify.update(toastId, options);
};

// Utility function to handle error messages consistently
export const getErrorMessageString = (error) => {
	return error?.response?.data?.message || error?.response?.data?.error?.message || error?.response?.data?.error || error?.message || error;
};

export const getErrorMessage = (error) => {
	const er = getErrorMessageString(error);

	if (typeof er === "string") {
		return <>{nl2br(er)}</>;
	}

	return er;
};

export default Toast;

const createToastErrorId = (error) => {
	const str = getErrorMessageString(error);
	try {
		const mesg = str
			.split("")
			.reduce((acc, char, index) => acc + char.charCodeAt(0) * (index + 1), 0)
			.toString(16);

		const code = error?.code || error.response?.data?.code || error.response?.data?.error?.code || error.response?.data?.error || error.code || error;

		const url =
			error?.response?.config?.url ||
			error.response?.data?.url ||
			error.response?.data?.error?.url ||
			error.response?.data?.error ||
			error.url ||
			error;

		const dateInMinutesExludingSeconds = Math.floor(Date.now() / 60000);
		return `${mesg}-${code}-${url}-${dateInMinutesExludingSeconds}`;
	} catch (error) {
		return str;
	}
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function generateCurlCommand(error) {
	const { config } = error;
	const method = config.method.toUpperCase();
	const url = `${config.baseURL}${config.url}`;

	// Headers
	const headers = Object.entries(config.headers || {})
		.map(([key, value]) => `-H "${key}: ${value}"`)
		.join(" ");

	// Query Params
	const params = Object.entries(config.params || {})
		.map(([key, value]) => `${key}=${value}`)
		.join("&");

	// Body/Data for POST/PUT
	const data = config.data ? `--data '${JSON.stringify(config.data)}'` : "";

	// Complete cURL Command
	const curlCommand = `curl -X ${method} "${url}?${params}" ${headers} ${data}`;

	return curlCommand;
}
