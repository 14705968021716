import React, { useCallback, useEffect, useState } from "react";
import { Popover, Scrollable } from "@shopify/polaris";
import "../../css/variables.scss";
import "../../css/popover.scss";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import Colors from "../Colors";
import { getPortalsMaxZIndex } from "../Utilities";

const MyPopover = ({ ...props }) => {
	const [zIndex, setZIndex] = useState(400);
	const [open, setOpen] = useState(false);
	const [headerHeight, setHeaderHeight] = useState(40);

	useEffect(() => {
		if (props.header) setTimeout(() => setZIndex(Math.random()), 0);
	}, [props.header]);

	useEffect(() => {
		if (props.active || props.open || open) {
			setZIndex(getPortalsMaxZIndex());
		}
	}, [props.active, props.open, open]);

	const onClose = () => {
		if ("open" in props || "active" in props) {
			// Do nothing
		} else {
			setOpen(false);
		}

		if (props.onClose) props.onClose();
	};

	const onClick = () => {
		if (props.activator?.onClick) props.activator?.onClick?.();
		if (props.activator?.props?.onClick) props.activator?.props?.onClick?.();
		setOpen(true);
	};

	const activator =
		"open" in props || "active" in props
			? props.activator
			: React.cloneElement(props.activator, {
					onClick,
			  });

	const isOpen = (props.active || props.open || open) && !props.disabled;

	const closeButton = (() => {
		if (!props.showCloseButton) return null;

		// eslint-disable-next-line no-constant-condition
		if (isMobile || true) {
			return (
				<button
					style={{
						paddingTop: "10px",
						paddingBottom: "10px",
						// width: "100%",
						flex: 1,
						background: "var(--main3)",
						fontSize: "0.7812rem",
						cursor: "pointer",
						color: "var(--textColor)",
						// position: "absolute",
						// borderTopWidth: "thin",
						bottom: 0,
						border: "none",
					}}
					onClick={(e) => {
						e.stopPropagation();
						onClose();
					}}
				>
					{props.t("common.actions.close", "Stäng")}
				</button>
			);
		}

		return null;
	})();

	const handleRef = useCallback((node) => {
		if (node) {
			setHeaderHeight(node.getBoundingClientRect().height || 40);
		}
	}, []);

	const content = props.header ? (
		<Popover.Pane fixed>
			<div ref={handleRef}>{props.header}</div>
			<Scrollable
				style={{
					position: "relative",
					// height: 350,
					padding: "var(--p-space-2) 0",
					maxHeight: Math.min(window.innerHeight * 0.75, 400 - (headerHeight + 50)),
					// paddingBottom: (headerHeight || 40) + 0,
				}}
			>
				{props.children}
				{closeButton}
			</Scrollable>
		</Popover.Pane>
	) : (
		<React.Fragment>
			{props.children}
			<ButtonsWrapper>
				{closeButton}
				{props.action}
			</ButtonsWrapper>
		</React.Fragment>
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Popover
			captureOverscroll
			fluidContent
			{...props}
			activator={activator}
			onClose={onClose}
			active={isOpen}
			zIndexOverride={Math.max(zIndex, props.minZIndex || 400)}
		>
			<div
				onClick={(e) => {
					e.stopPropagation();
					// e.preventDefault();
				}}
			>
				{content}
			</div>
		</Popover>
	);
};

MyPopover.Section = Popover.Section;

export default withTranslation(["common"], { withRef: true })(MyPopover);
MyPopover.defaultProps = {
	showCloseButton: true,
};
const ButtonsWrapper = styled.div`
	display: flex;

	&&&& > * {
		flex: 1;

		&:not(.Polaris-Button--primary) {
			border-radius: 0;
		}

		&.Polaris-Button[type="submit"]:not(.Polaris-Button--primary) {
			color: ${Colors.green};
		}
	}
`;
