import React, { useEffect, useMemo, useRef, useState } from "react";
import TargetProgressBox from "./TargetProgressBox";
import { TargetProgressBox as TargetProgressBoxWrapper } from "./styles";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const MINWIDTH = 285;

const TargetProgressBoxes = ({ history }) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [wrapperWidth, setWrapperWidth] = useState(0);
	const targets = useSelector((state: any) => state.targets);

	useEffect(() => {
		if (!wrapperRef.current) return;
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setWrapperWidth(entry.contentRect.width);
			});
		});

		resizeObserver.observe(wrapperRef.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const { count } = useMemo(() => {
		const count = Math.floor(wrapperWidth / MINWIDTH);
		const width = Math.max(Math.floor(wrapperWidth / count), MINWIDTH);
		return {
			count,
			width,
		};
	}, [wrapperWidth]);

	return (
		<Wrapper ref={wrapperRef} count={count}>
			{targets.map((target, i) => (
				<TargetProgressBox key={i} target={target} history={history} />
			))}
		</Wrapper>
	);
};
export default withRouter(TargetProgressBoxes);

const Wrapper = styled.div<{
	count: number;
}>`
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 3rem;
	width: 100%;

	&& {
		${TargetProgressBoxWrapper},
		.TargetProgressBoxWrapper {
			min-width: ${MINWIDTH}px;
			flex-basis: calc(100% / ${(props) => props.count} - 2rem);
		}
	}
`;
