/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormLayout, Select, Spinner, TextField } from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import API from "../API";
import useQuery from "../hooks/useQuery";
import Toast from "src/js/components/Toast";

const ContactFortnoxContent = ({ integration, customer, form, onChange, loading }) => {
	const { t } = useTranslation(["contact"]);
	const params = useMemo(
		() => ({
			type: "invoice",
		}),
		[]
	);

	const handleOnChange = useCallback((v) => onChange(v), [onChange]);

	const { data: printTemplates, isInitialLoading: loadingTemplates } = useQuery({
		queryKey: ["fortnox_print_templates", params],
		queryFn: async () => {
			try {
				const result = await API.get(`/api/integrations/fortnox/${integration?.id}/templates/print.json?type=invoice`);

				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}

				return (
					result.data.PrintTemplates?.filter(({ Template }) => {
						if (["stocr", "iban", "bg", "constr", "cash", "rem"].includes(Template)) return false;
						return true;
					}) || []
				);
			} catch (error) {
				console.error("error:", error);
			}
		},
		initialData: [],
	});

	useEffect(() => {
		handleOnChange(customer);
	}, [customer, handleOnChange]);

	const getUpdateHandler = (field) => (value) => {
		const newForm = { ...form };

		if (field === "template_invoice") {
			newForm.DefaultTemplates.Invoice = value;
		} else {
			newForm[field] = value;
		}

		handleOnChange(newForm);
	};

	const getUpdateDefaultDeliveryTypesHandler = (field) => (value) => {
		const newForm = { DefaultDeliveryTypes: {}, ...form };
		newForm.DefaultDeliveryTypes[field] = value;

		getUpdateHandler("DefaultDeliveryTypes")(newForm.DefaultDeliveryTypes);
	};

	// eslint-disable-next-line react/no-unstable-nested-components
	const Label = ({ label }) => <span style={{ display: "flex", alignItems: "center", gap: "0.6250rem" }}>{label}</span>;

	const deliveryOptions = [
		{
			value: "",
			label: "Välj..",
		},
		{
			value: "PRINT",
			label: "Utskrift",
		},
		{
			value: "EMAIL",
			label: "E-post",
		},
		{
			value: "PRINTSERVICE",
			label: "E-faktura",
		},
	];

	return (
		<div className="contact_fornox">
			<FormLayout>
				<TextField
					disabled={loading}
					label={
						<React.Fragment>
							{t("contact.fortnox.fields.EmailInvoice.label", "Faktura email")} {loading && <Spinner size="small" />}
						</React.Fragment>
					}
					onChange={getUpdateHandler("EmailInvoice")}
					value={form.EmailInvoice}
				/>
				<TextField
					disabled={loading}
					label={
						<React.Fragment>
							{t("contact.fortnox.fields.EmailOrder.label", "Order email")} {loading && <Spinner size="small" />}
						</React.Fragment>
					}
					onChange={getUpdateHandler("EmailOrder")}
					value={form.EmailOrder}
				/>
				<TextField
					disabled={loading}
					label={
						<React.Fragment>
							{t("contact.fortnox.fields.EmailOffer.label", "Offert email")} {loading && <Spinner size="small" />}
						</React.Fragment>
					}
					onChange={getUpdateHandler("EmailOffer")}
					value={form.EmailOffer}
				/>
				<TextField
					disabled={loading}
					label={
						<React.Fragment>
							{t("contact.terms.fortnox.out_reference", "Vår referens")} {loading && <Spinner size="small" />}
						</React.Fragment>
					}
					onChange={getUpdateHandler("OurReference")}
					value={form.OurReference}
				/>

				<TextField
					disabled={loading}
					label={
						<React.Fragment>
							{t("contact.terms.fortnox.your_reference", "Er referens")} {loading && <Spinner size="small" />}
						</React.Fragment>
					}
					onChange={getUpdateHandler("YourReference")}
					value={form.YourReference}
				/>

				<Select
					label="Faktura leveransätt"
					options={deliveryOptions}
					onChange={getUpdateDefaultDeliveryTypesHandler("Invoice")}
					value={form?.DefaultDeliveryTypes?.Invoice}
				/>
				<Select
					label="Order leveransätt"
					options={deliveryOptions}
					onChange={getUpdateDefaultDeliveryTypesHandler("Order")}
					value={form?.DefaultDeliveryTypes?.Order}
				/>
				<Select
					label="Offert leveransätt"
					options={deliveryOptions}
					onChange={getUpdateDefaultDeliveryTypesHandler("Offer")}
					value={form?.DefaultDeliveryTypes?.Offer}
				/>

				<Select
					disabled={loadingTemplates || loading}
					label={
						<Label
							label={
								<React.Fragment>
									{t("contact.terms.print_template", "Utskriftsmall")} {loadingTemplates && <Spinner size="small" />}
								</React.Fragment>
							}
						/>
					}
					options={printTemplates.map((item) => ({ label: item.Name, value: item.Template }))}
					placeholder="Default"
					onChange={getUpdateHandler("template_invoice")}
					value={form.DefaultTemplates && form.DefaultTemplates.Invoice}
				/>
			</FormLayout>
		</div>
	);
};
export default ContactFortnoxContent;
