import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import IndexTable from "src/js/components/IndexTable";
import ProfileAvatar from "src/js/components/ProfileAvatar";

const DialList = () => {
	const { t } = useTranslation(["dial_lists", "common"]);
	const history = useHistory();

	return (
		<>
			<IndexTable
				title={t("dial_lists.list.title", "Ringlistor") as string}
				limit={25}
				primaryAction={{
					content: t("dial_lists.list.create", "Skapa ny ringlista") as string,
					url: "/admin/dial_lists/create",
				}}
				resourceUrl="/api/dial_lists.json"
				filterKey="dial_lists"
				resourceHandle="dial_lists"
				resourceName={{
					singular: "ringlista",
					plural: "ringistor",
				}}
				onClick={(row) => {
					history.push(`/admin/dial_lists/${row.id}`);
				}}
				columns={[
					{
						label: t("dial_lists.list.fields.title.label", "Titel"),
						handle: "TITLE",
						render: (row) => row?.title,
					},
					// {
					// 	label: t("dial_lists.list.fields.counts.label", "Ej bearbetade/kvalificerade/ignorerade"),
					// 	handle: "COUNTS",
					// 	render: (row) => (
					// 		<>
					// 			<Badge color={Colors.blue}>{row.unhandled_prospect_count}</Badge>
					// 			{"/"}
					// 			<Badge color={Colors.green}>{row.qualified_prospect_count}</Badge>
					// 			{"/"}
					// 			<Badge color={Colors.red}>{row.denied_prospect_count}</Badge>
					// 		</>
					// 	),
					// },
					{
						label: t("dial_lists.list.fields.assigned_to_user.label", "Tilldelad till"),
						handle: "ASSIGNED_TO_USER",
						render: (row) => (
							<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
								<ProfileAvatar size={26} user={row.assigned_to_user} />
								{row.assigned_to_user?.name}
							</div>
						),
					},
					{
						label: t("dial_lists.list.fields.created_by_user.label", "Skapad av"),
						handle: "CREATED_AT",
						render: (row) => (
							<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
								<ProfileAvatar size={26} user={row.created_by_user} />
								{row.created_by_user?.name}
							</div>
						),
					},
					{
						label: t("dial_lists.list.fields.created_at.label", "Skapad"),
						handle: "CREATED_AT",
						render: (row) => row.created_at,
					},
				]}
			/>
		</>
	);
};
export default DialList;
