import React, { useContext } from "react";
import CustomfieldContext from "./context";
import { InfoWrapper } from "./styles";
import ResourceListManager from "../ResourceListManager";
import ItemCard from "src/js/components/ItemCard";
import Colors from "src/js/Colors";
import { withTranslation } from "react-i18next";
import Badge from "src/js/components/Badge";
import truncateString from "src/js/Utilities/truncateString";

const Articles = ({ history, t }) => {
	const { id } = useContext(CustomfieldContext);

	const filters = [];

	const renderItem = (item: any) => {
		return (
			<ItemCard
				title={
					<>
						{item.title}
						{item.connected ? (
							<Badge color={Colors.green}>{t("common.connected", "Ansluten")}</Badge>
						) : (
							<Badge color={Colors.grey}>{t("common.notConnected", "Ej Ansluten")}</Badge>
						)}
					</>
				}
				leftColumn={[
					{
						label: t("articles.status", "Status"),
						value: item.disabled ? t("common.inactive", "Inaktiv") : t("common.active", "Aktiv"),
					},
					{
						label: t("articles.articleNumber", "Artikelnummer"),
						value: item.number,
					},
					{
						label: t("articles.description", "Beskrivning"),
						value: truncateString(item.description ?? "", 20),
					},
					{
						label: t("articles.categories", "Kategorier"),
						value: item.categories_display,
					},
					{
						label: t("articles.pricelists", "Prislistor"),
						value: item.pricelists_display,
					},
				]}
				rightColumn={
					[
						/* {
						label: t("articles.purchasePrice", "Inköpspris"),
						value: currencyFormatter({ value: item.in_price ?? 0 }),
					}, */
					]
				}
			/>
		);
	};

	return (
		<InfoWrapper>
			<ResourceListManager
				appliedFilters={[
					{
						key: "disabled",
						value: "0",
					},
				]}
				onClick={(item) => {
					history.push(`/admin/articles/${item.id}`, {
						data: item,
					});
				}}
				params={{ custom_field_id: id }}
				resourceHandle={"articles"}
				filterKey={"filterKey"}
				history={history}
				renderItem={renderItem}
				filters={filters}
				resourceUrl="/api/articles.json"
				resourceName={{ singular: t("articles.singular", "Artikel"), plural: t("articles.plural", "Artiklar") }}
				addRemoveUrl={`/api/custom_fields/${id}/articles.json`}
			/>
		</InfoWrapper>
	);
};
export default withTranslation(["articles", "common"])(Articles);
