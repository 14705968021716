/* eslint-disable quotes */
import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Button, type ComplexAction } from "@shopify/polaris";
import { WithTranslation, withTranslation } from "react-i18next";
import { store } from "../../store";
import SheetHeader from "../../StyledComponents/SheetHeader";
import Banner from "../banner";
import useMountTransition from "../../hooks/useMountTransition";
import { getPortalsMaxZIndex } from "../../Utilities";
import ConfirmPopup from "../modal/ConfirmPopup";
import SheetWidthResizer from "./SheetWidthResizer";
import useSheetSettings from "./useSheetSettings";

const ANIMATION_IN = 100;
const ANIMATION_OUT = 250;
const DEFAULT_ZINDEX = 519;

interface SecondaryAction extends ComplexAction {
	confirm?: boolean;
}
type Action = ComplexAction & {
	confirm?: boolean;
	primary?: boolean;
};

interface SheetProps extends WithTranslation {
	open: boolean;
	noTransition?: boolean;
	onClose: (e?: EventSource) => void;
	title: string | React.ReactNode;
	hideHeader?: boolean;
	status?: string;
	children?: any;
	scrollable?: boolean;
	primaryAction?: ComplexAction;
	secondaryActions?: SecondaryAction[];
	actions?: (Action | any)[];
	minZIndex?: number;
}

const Sheet = ({
	children,
	onClose,
	open,
	title,
	hideHeader,
	status,
	noTransition,
	scrollable = true,
	primaryAction,
	secondaryActions,
	actions,
	t,
	minZIndex,
	...rest
}: SheetProps): JSX.Element | null => {
	const hasTransitionedIn = useMountTransition(open, ANIMATION_OUT);

	const [zIndex, setZIndex] = useState<number | undefined>(DEFAULT_ZINDEX);
	const [id, setId] = useState<number | undefined>(undefined);

	const {
		sheetSettings: { isPinned },
		updateSheetSettingsKey,
	} = useSheetSettings();

	const toggleIsPinned = () => {
		updateSheetSettingsKey("isPinned", !isPinned);
	};

	const handleOnClose = useCallback(
		(e: any = null) => {
			e?.stopPropagation();
			if ([2, 419, 418].includes(store.getState().user.id)) {
				onClose(e);
			}
		},
		[onClose]
	);

	const onKeyDown = useCallback(
		(e: any) => {
			if (e.key === "Escape") handleOnClose(e);
		},
		[handleOnClose]
	);

	useEffect(() => {
		if (open) {
			setZIndex(Math.max(DEFAULT_ZINDEX, minZIndex || 0, getPortalsMaxZIndex()) + 2);
		}
	}, [open, minZIndex]);

	const show = open || hasTransitionedIn;
	const container: Element | null = document.querySelector("#PolarisPortalsContainer") || document.querySelector("body");

	const [headerHeight, setHeaderHeight] = useState(0);
	const [footerHeight, setFooterHeight] = useState(0);

	const handleHeaderRef = useCallback((node) => {
		if (!node) return;
		setHeaderHeight(node.clientHeight);
	}, []);

	const handleFooterRef = useCallback((node) => {
		if (!node) return;
		setFooterHeight(node.clientHeight);
	}, []);

	// useLayoutEffect(() => {
	// 	setHeaderHeight(headerRef?.current?.clientHeight || 0);
	// 	setFooterHeight(footerRef?.current?.clientHeight || 0);
	// }, [show]);

	const handleRef = useCallback((node) => {
		// React calls this whenever 'ref' changes
		// (i.e., when the node mounts or unmounts)
		if (!node) return;

		// Find the index in the DOM
		const children = document.querySelector("#PolarisPortalsContainer")?.children || [];
		const index = Math.max(Array.from(children).indexOf(node), 0);

		// Directly set the attribute to avoid extra re-render
		node.setAttribute("data-id", index);
		setId(index);
	}, []);

	if (!container) return null;

	const footerEle = (primaryAction || secondaryActions?.length || actions?.length) && (
		<Footer ref={handleFooterRef}>
			<FlexActionsContainer>
				{primaryAction && (
					<ModalActionButton
						primary={!primaryAction.destructive}
						onAction={primaryAction.onAction}
						icon={primaryAction.icon}
						loading={primaryAction.loading}
						disabled={primaryAction.disabled}
						destructive={primaryAction.destructive}
					>
						{primaryAction.content}
					</ModalActionButton>
				)}

				{!!actions?.length && (
					<FlexActionsContainer>
						{actions?.map((action, index) => {
							if (React.isValidElement(action)) return action;

							const { content, onAction, loading, disabled, destructive, icon, confirm, primary } = action;
							if (destructive && confirm) {
								return (
									<ConfirmPopup
										key={index}
										activator={
											<ModalActionButton
												key={index}
												onAction={onAction}
												icon={icon}
												loading={loading}
												disabled={disabled}
												destructive={destructive}
												primary={primary}
											>
												{content}
											</ModalActionButton>
										}
									/>
								);
							}

							return (
								<ModalActionButton key={index} onAction={onAction} icon={icon} loading={loading} disabled={disabled} destructive={destructive}>
									{content}
								</ModalActionButton>
							);
						})}
					</FlexActionsContainer>
				)}
			</FlexActionsContainer>
			{!!secondaryActions?.length && (
				<FlexActionsContainer>
					{secondaryActions?.map((secondaryAction, index) => {
						const { content, onAction, loading, disabled, destructive, icon, confirm } = secondaryAction;
						if (destructive && confirm) {
							return (
								<ConfirmPopup
									key={index}
									activator={
										<ModalActionButton key={index} onAction={onAction} icon={icon} loading={loading} disabled={disabled} destructive={destructive}>
											{content}
										</ModalActionButton>
									}
								/>
							);
						}

						return (
							<ModalActionButton key={index} onAction={onAction} icon={icon} loading={loading} disabled={disabled} destructive={destructive}>
								{content}
							</ModalActionButton>
						);
					})}
				</FlexActionsContainer>
			)}
		</Footer>
	);

	return createPortal(
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Wrapper data-pinned={isPinned} ref={handleRef} data-id={id} data-zindex={(open && zIndex) || null} {...rest}>
			{show && (
				<div>
					<InnerContainer
						data-pinned={isPinned}
						data-zindex={zIndex}
						onKeyDown={onKeyDown}
						data-hastransitionedin={hasTransitionedIn || noTransition}
						data-notransition={noTransition}
						data-open={open}
					>
						<InnerWrapper style={{ position: "relative" }}>
							<SheetWidthResizer />

							{!hideHeader && (
								<div ref={handleHeaderRef}>
									{status ? (
										<Banner status={status as any} title={title} onDismiss={onClose} style={{ padding: "0.6250rem" }} />
									) : (
										<SheetHeader title={title} onClose={onClose} isPinned={isPinned} toggleIsPinned={toggleIsPinned} />
									)}
								</div>
							)}
							{scrollable ? (
								<ScrollContainer headerHeight={headerHeight} footerHeight={footerHeight}>
									{children}
								</ScrollContainer>
							) : (
								children
							)}
							{footerEle}
						</InnerWrapper>
					</InnerContainer>
				</div>
			)}

			{open && !isPinned && <Backdrop data-zindex={(zIndex || DEFAULT_ZINDEX) - 1} onClick={handleOnClose} />}
		</Wrapper>,
		container
	);
};
export default withTranslation(["common"], { withRef: true })(Sheet);
const ScrollContainer = styled.div<{ headerHeight?: number; footerHeight?: number }>`
	overflow-y: auto;
	max-height: 100vh;
	height: ${({ headerHeight = 0, footerHeight = 0 }) => `calc(100% - calc(${headerHeight}px + ${footerHeight}px))`};
`;
const Wrapper = styled.div.attrs((props) => ({ "data-portal-id": `sheet-Polarisportal${props["data-id"]}` }))``;

const InnerWrapper = styled.div.attrs({ className: "Polaris-Sheet", role: "dialog", "aria-modal": "true", tabindex: "-1" })``;

type BackdropProps = {
	onClick: () => void;
	"data-zindex"?: number | null | undefined;
};
const Backdrop = styled.div.attrs<BackdropProps>({
	className: "Polaris-Backdrop Polaris-Backdrop--transparent",
})`
	z-index: ${(props) => props["data-zindex"] || 518};
`;

type InnerContainerProps = {
	"data-hastransitionedin"?: boolean | undefined | null;
	"data-open"?: boolean;
	"data-notransition"?: boolean;
	"data-zindex"?: number | null | undefined;
};
const InnerContainer = styled.div.attrs<InnerContainerProps>(() => ({
	className: "Polaris-Sheet__Container",
	"data-polaris-layer": "true",
	"data-polaris-overlay": "true",
}))`
	transition: ${(props) => {
		if (props["data-notransition"]) return null;

		if (props["data-open"]) {
			return `transform ${ANIMATION_IN}ms, opacity ${ANIMATION_IN / 2}ms;`;
		}
		return `transform ${ANIMATION_OUT}ms, opacity ${ANIMATION_OUT * 2}ms;`;
	}};
	transform: ${(props) => (props["data-hastransitionedin"] && props["data-open"] ? "translateX(0)" : "translateX(100%)")};
	z-index: ${(props) => props["data-zindex"] || 519};
`;

const Footer = styled.div.attrs({ className: "Polaris-Sheet__Footer" })`
	display: flex;
	justify-content: space-between;
	gap: 1.25rem;
	padding: 1.25rem;
`;

const FlexActionsContainer = styled.div.attrs({ className: "Polaris-Sheet__SecondaryActions" })`
	display: flex;
	gap: 0.3125rem;
`;

// ModalActionButton Component
const ModalActionButton = ({ children, onAction, loading, onClick, ...props }: any) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleAction = async () => {
		try {
			setIsLoading(true);
			await onClick?.();
			await onAction?.();
		} catch (error) {
			console.error("error:", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Button {...props} onClick={handleAction} loading={loading || isLoading}>
			{children}
		</Button>
	);
};
