import React from "react";
import IndexTable from "src/js/components/IndexTable";
import ItemCard from "src/js/components/ItemCard";
import { useProspectContext } from "../Context/ProspectContext";
import styled from "styled-components";
import Button from "src/js/components/Button";
import { Icon } from "@shopify/polaris";
import { ThumbsDownMinor, ThumbsUpMinor } from "@shopify/polaris-icons";
import { useHistory } from "react-router-dom";
import { numberFormatter } from "src/js/Utilities";
import Badge from "src/js/components/Badge";
import getStatsBoxes from "../../Utilities/getStatsBoxes";

const CardList = () => {
	const { id, t, handleLikeAction, handleDislikeAction, stats, isLoadingStats, bulkActions } = useProspectContext();
	const history = useHistory();
	const ref = React.useRef<any>(null);

	const renderCard = (row: ProspectType) => {
		const latestFinancials = row?.axicon_company?.latest_financial;

		return (
			<ItemCard
				onClick={() => {
					history.push(`/admin/prospect_lists/${id}/prospect/${row.id}`);
				}}
				title={
					<>
						{row.axicon_company?.name}
						{row.qualified && <Badge status="success">Kvalificerad</Badge>}
						{row.denied && <Badge status="critical">Ignorerad</Badge>}
					</>
				}
				rightTopElement={
					<ButtonsWrapper
						subdued={row.qualified || row.denied}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						<Button
							outline
							primary
							icon={<Icon source={ThumbsUpMinor} />}
							onClick={async (e) => {
								await handleLikeAction(row.id);
								row.qualified = true;
								ref.current?.refresh();
							}}
							connectedDisclosure={{
								actions: [
									{
										content: "Importera",
										onAction: async () => {
											await handleLikeAction(row.id, true);
											row.qualified = true;
											ref.current?.refresh();
										},
									},
								],
							}}
						>
							Kvalificera
						</Button>

						<Button
							outline
							destructive
							icon={<Icon source={ThumbsDownMinor} />}
							onClick={async () => {
								await handleDislikeAction(row.id);
								row.denied = true;
								ref.current?.refresh();
							}}
						>
							Ignorera
						</Button>
					</ButtonsWrapper>
				}
				leftColumn={[
					{
						label: t("prospects.fields.organisational_number.label", "Organisationsnummer"),
						value: row.axicon_company?.org_number,
					},
					{
						label: t("prospects.fields.turnover.label", "Omstättning"),
						value: numberFormatter({ value: latestFinancials?.turnover as any }),
					},
					{
						label: t("prospects.fields.profit.label", "Vinst/Förlust"),
						value: numberFormatter({ value: latestFinancials?.profit as any }),
					},
					{
						label: t("prospects.fields.profit_margin.label", "Vinstmarginal (%)"),
						value: `${latestFinancials?.profit_margin || "-"}%`,
					},
				]}
				rightColumn={[
					{
						label: t("prospects.fields.city	.label", "Stad"),
						value: row.axicon_company?.city,
					},
				]}
			/>
		);
	};

	return (
		<IndexTable
			promotedBulkActions={bulkActions}
			stats={getStatsBoxes(stats, isLoadingStats, t)}
			inline
			limit={20}
			ref={ref}
			resourceUrl={`/api/prospect_lists/${id}/prospects.json`}
			resourceHandle="prospects"
			filterKey="prospects"
			resourceName={{
				singular: "prospekt",
				plural: "prospekt",
			}}
			columns={[
				{
					handle: "",
					label: "",
					render: renderCard,
				},
			]}
		/>
	);
};
export default CardList;
const ButtonsWrapper = styled.div<{ subdued?: boolean }>`
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;

	.Polaris-Button {
		transform: scale(0.9);

		.Polaris-Button__Content {
			/* font-size: 12px;
			line-height: 12px; */
		}
	}

	.Polaris-ButtonGroup {
		transform: scale(0.9);

		.Polaris-Button {
			transform: scale(1);
		}
	}

	& > * {
		transition: opacity 0.3s;
		opacity: ${(props) => (props.subdued ? 0.5 : 1)};

		&:hover {
			opacity: 1 !important;
		}
	}
`;
// const TitleWrapper = styled.div`
// 	display: flex;
// 	gap: 1rem;
// 	justify-content: space-between;
// `;
