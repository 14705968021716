import React, { Component } from "react";
import { ActionList, Checkbox, Button, Icon, LegacyStack, Tooltip as PolarisTooltip } from "@shopify/polaris";
import {
	CircleChevronDownMinor,
	ChatMajor,
	EditMinor,
	CircleRightMajor,
	CircleDisableMinor,
	CircleTickOutlineMinor,
	DeleteMajor,
	ArchiveMajor,
	DuplicateMinor,
} from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../../API";
import BoardCell from "./BoardCell.js";
import Tooltip from "./components/Tooltip.js";
import BoardSelector from "./components/BoardSelector.js";
import MissingColumnConfirmationModal from "./components/MissingColumnConfirmationModal.js";
import { store } from "../../store";
import RowSheet from "./components/RowSheet";
import BoardHelper from "./BoardHelper";
import Popover from "../../components/Popover.js";
import Toast from "src/js/components/Toast";

class BoardRow extends Component {
	constructor(props) {
		super(props);

		this.state = { renderType: "placeholder", title: props.row ? props.row.title : "", newname: props.input || "" };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ renderType: props.mode, title: props.row ? props.row.title : "", newname: props.input || "" });
	}

	changeRowTitle() {
		this.setState({ editingTitle: false });
		if (this.state.title != this.props.row.title) {
			this.props.row.title = this.state.title;
			store.dispatch({ type: "UPDATE_BOARD_ROW", row: this.props.row });

			API.put("/api/boards/" + this.props.board_id + "/rows/" + this.props.row.id + ".json", { title: this.state.title }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
					}
				})
				.catch((error) => {
					Toast.error(error);
				});
		}
	}

	archiveRow(undoArchive, row) {
		this.setState({ saving: true, showContext: false });
		if (undoArchive) {
			const roww = row || this.props.row;
			roww.archived = false;
			BoardHelper.addRowAtOldPosition(roww.id);
		} else {
			BoardHelper.removeRow(this.props.row.id);
		}

		API.put(
			"/api/boards/" + this.props.board_id + "/rows/" + (row ? row.id : this.props.row.id) + ".json",
			{ archived: !undoArchive },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				if (result.data.row && result.data.row.archived) {
					Toast.warning(
						<>
							<p>{this.props.t("row.responses.archived", "Arkivarde rad")}</p>
							<p>{this.props.t("row.responses.revert_archived", "Klicka här för att ångra")}</p>
						</>,
						{
							timeOut: 12000,
							onClick: () => this.archiveRow(true, result.data.row),
						}
					);
				} else {
					Toast.success(this.props.t("board.responses.reverted_archived", "Ångrade arkiverade rad") + ` "${row ? row.title : this.props.row.title}"`);
				}

				this.setState({ saving: false });
			})
			.catch((error) => {
				console.error("error: ", error);
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	removeRow(undo, row) {
		this.setState({ saving: true, showContext: false });
		const params = Object.assign({}, {});
		if (undo) {
			const roww = row || this.props.row;
			roww.removed = false;
			BoardHelper.addRowAtOldPosition(roww.id);
			params.undo = true;
		} else {
			BoardHelper.removeRow(this.props.row.id);
		}

		API.delete("/api/boards/" + this.props.board_id + "/rows/" + (row ? row.id : this.props.row.id) + ".json", {
			params,
		})
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				if (result.data.row && result.data.row.removed) {
					Toast.warning(
						<>
							<p>{this.props.t("row.responses.removed", "Tog bort rad")}</p>
							<p>{this.props.t("row.responses.revert_removed", "Klicka här för att ångra")}</p>
						</>,

						{
							timeOut: 12000,
							onClick: () => this.removeRow(true, result.data.row),
						}
					);
				} else {
					Toast.success(this.props.t("row.responses.reverted_removed", "Ångrade rad") + ` "${row ? row.title : this.props.row.title}"`);
				}
				this.setState({ saving: false });
			})
			.catch((error) => {
				console.error("error: ", error);
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	moveRowToGroup(group, force = false) {
		BoardHelper.removeRow(this.props.row.id);
		const data = { group };
		if (force) {
			data.force = true;
			data.custom_mappings = this.state.missing_columns;
		}
		this.setState({ saving: true, showContext: false });
		API.post("/api/boards/" + this.props.board_id + "/rows/" + this.props.row.id + "/move.json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.unmapped) {
					this.setState({
						saving: false,
						failed_move_group: { id: result.data.group_id },
						missing_columns: result.data.unmapped,
						mapped_columns: result.data.mappings,
						failed_move_board: result.data.board,
					});
					return;
				}
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				Toast.success(this.props.t("row.responses.moved", "Flyttade rad") + " " + this.props.row.title);
				this.setState({ saving: false, failed_move_group: false });
			})
			.catch((error) => {
				console.error("error: ", error);
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	changeGroup(group) {
		this.setState({ saving: true, showContext: false });
		API.put("/api/boards/" + this.props.board_id + "/rows/" + this.props.row.id + ".json", { group_id: group.id }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.setState({ saving: false });
				if (this.props.onUpdateRow) this.props.onUpdateRow(result.data.row);
			})
			.catch((error) => {
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	toggleSelection() {
		this.props.toggleSelection();
	}

	getRowTickets() {
		if (!this.props.row.column_values) return [];
		const tickets = [];
		for (let i = 0; i < BoardHelper.getBoard(this.props.board_id)?.columns?.length; i++) {
			if (BoardHelper.getBoard(this.props.board_id).columns[i].type == "ticket") {
				const column = BoardHelper.getBoard(this.props.board_id).columns[i];
				if (column.id in this.props.row.column_values) {
					if (this.props.row.column_values[column.id].value) {
						for (let x = 0; x < this.props.row.column_values[column.id].length; x++) {
							if (this.props.row.column_values[column.id].value[x] in store.getState().board_tickets) {
								tickets.push(store.getState().board_tickets[this.props.row.column_values[column.id].value[x]]);
							}
						}
					}
				}
			}
		}
		return tickets;
	}

	shouldShowArchives() {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get("archived") == 1;
	}

	render() {
		if (this.props.summary) {
			if (this.state.renderType == "placeholder") {
				return <div className="board-row" />;
			}
			return (
				<div style={{ height: 36, display: "flex" }}>
					<div
						className="fixed-column column-title"
						style={{
							display: "flex",
							flexBasis: BoardHelper.getBoard(this.props.board_id)?.title_width,
							minWidth: BoardHelper.getBoard(this.props.board_id)?.title_width,
							maxWidth: BoardHelper.getBoard(this.props.board_id)?.title_width,
						}}
					/>
					{BoardHelper.getBoard(this.props.board_id)?.columns?.map((column) => {
						if (column.have_summary) {
							return (
								<BoardCell
									summary
									key={column.id}
									board={BoardHelper.getBoard(this.props.board_id)}
									column={column}
									rows={this.props.rows}
									history={this.props.history}
								/>
							);
						} else {
							return (
								<div
									key={column.id}
									className={"column-" + column.id}
									style={{
										display: "flex",
										flexBasis: column.width,
										minWidth: column.width,
										maxWidth: column.width,
									}}
								/>
							);
						}
					})}
				</div>
			);
		}

		const group = BoardHelper.getGroup(this.props.row.group_id) || {};

		if (this.props.quickadd) {
			return (
				<div className="board-row 1">
					<div className="fixed-column" style={{ opacity: 0.8, width: $("." + this.props.boardClass).width() - 10, height: 36 }}>
						<div style={{ position: "relative", width: "100%" }}>
							<LegacyStack spacing="none">
								<div className="board-spacer">
									<div style={{ textAlign: "center", width: "100%" }} />
								</div>
								<LegacyStack.Item fill>
									<LegacyStack spacing="none">
										<span
											style={{
												display: "inline-block",
												height: 36,
												width: 8,
												backgroundColor: group.color,
											}}
										/>
										<LegacyStack.Item fill>
											<input
												className={"quickadd" + this.props.row.group_id}
												type="text"
												value={this.state.newname}
												onChange={(event) => {
													this.setState({ newname: event.target.value });
												}}
												onKeyPress={(event) => {
													if (event.key === "Enter") {
														if (BoardHelper.isSupportBoard(this.props.board_id) && this.props.openTicketModal) {
															this.props.openTicketModal(this.state.newname);
															return;
														}
														BoardHelper.createRow(this.props.board_id, this.state.newname, this.props.position, this.props.row.group_id);
														this.setState({ newname: "" });
														setTimeout(() => {
															$("input.quickadd" + this.props.row.group_id).focus();
														}, 50);
													}
												}}
												style={{ width: "100%", height: 36 }}
												placeholder="+Ny"
											/>
										</LegacyStack.Item>
									</LegacyStack>
								</LegacyStack.Item>
							</LegacyStack>
						</div>
					</div>
				</div>
			);
		}
		if (this.state.renderType == "placeholder") {
			return (
				<div className="board-row 4">
					<div style={{ height: 36, display: "flex" }}>
						<div
							className="fixed-column column-title"
							style={{
								display: "flex",
								flexBasis: BoardHelper.getBoard(this.props.board_id)?.title_width,
								minWidth: BoardHelper.getBoard(this.props.board_id)?.title_width,
								maxWidth: BoardHelper.getBoard(this.props.board_id)?.title_width,
							}}
						>
							<div style={{ width: "100%" }}>
								<LegacyStack spacing="none" wrap={false}>
									<div className="board-spacer" />
									<LegacyStack.Item fill>
										<div className="board-column" style={{ width: "100%" }}>
											<LegacyStack spacing="none" wrap={false}>
												<div className="selectbox" style={{ backgroundColor: group.color }}>
													<div>
														<Checkbox checked={this.props.selected} />
													</div>
												</div>
												<LegacyStack.Item fill>
													<div>
														<a style={{ paddingLeft: 10, paddingTop: 5, display: "block" }}>
															<span
																className="overflow-ellipsis board-row-title"
																style={{
																	maxWidth: "100%",
																	display: "inline-block",
																	position: "relative",
																	cursor: "pointer",
																	paddingTop: 1,
																}}
															>
																{this.props.row.title}
															</span>
														</a>
													</div>
												</LegacyStack.Item>
											</LegacyStack>
										</div>
									</LegacyStack.Item>
								</LegacyStack>
							</div>
						</div>
						<div className="board-column" style={{ width: "100%" }} />
					</div>
				</div>
			);
		}

		const contextButton = (
			<div className="board-show-hover" style={{ textAlign: "center", width: "100%", paddingTop: 5 }}>
				<Button
					plain
					onClick={() => {
						this.setState({
							showContext: !this.state.showContext,
							showMove: !this.state.showContext ? false : this.state.showMove,
							showMoveGroup: !this.state.showContext ? false : this.state.showMoveGroup,
						});
					}}
					icon={CircleChevronDownMinor}
				/>
			</div>
		);
		const actions = (
			<Popover
				active={this.state.showContext}
				fixed
				activator={contextButton}
				onClose={() => {
					this.setState({ showContext: false });
				}}
			>
				{this.state.showMove ? (
					<BoardSelector onSelect={this.moveRowToGroup.bind(this)} board={BoardHelper.getBoard(this.props.board_id)} requireGroup />
				) : this.state.showMoveGroup ? (
					<BoardSelector onSelect={this.changeGroup.bind(this)} board={BoardHelper.getBoard(this.props.board_id)} sameBoard />
				) : (
					<span
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<ActionList
							items={[
								{
									content: this.props.selected
										? this.props.t("row.actions.unmark", "Avmarkera rad")
										: this.props.t("row.actions.mark", "Markera rad"),
									icon: this.props.selected ? CircleDisableMinor : CircleTickOutlineMinor,
									onAction: () => {
										this.toggleSelection();
										this.setState({ showContext: false });
									},
								},
								{
									content: this.props.t("row.actions.move_to_group", "Flytta till grupp"),
									icon: CircleRightMajor,
									onAction: () => {
										this.setState({ showMoveGroup: true });
									},
								},
								{
									content: this.props.t("row.actions.move_to_board", "Flytta till board"),
									icon: CircleRightMajor,
									onAction: () => {
										this.setState({ showMove: true });
									},
								},
								{
									content: this.props.t("row.actions.duplicate", "Duplicera"),
									icon: DuplicateMinor,
									onAction: () => {
										this.setState({ showContext: false });
										this.props.onDuplicateRow(this.props.row, this.props.row.position + 1);
									},
								},
								this.props.row.archived
									? {
											content: this.props.t("row.actions.revert_archived", "Ångra arkiverad rad"),
											icon: ArchiveMajor,
											onAction: this.archiveRow.bind(this, true),
									  }
									: {
											content: this.props.t("row.actions.archive", "Arkivera rad"),
											destructive: true,
											icon: ArchiveMajor,
											onAction: this.archiveRow.bind(this, false),
									  },

								{
									content: this.props.t("row.actions.remove", "Ta bort rad"),
									destructive: true,
									icon: DeleteMajor,
									onAction: this.removeRow.bind(this, false, null),
								},
							]}
						/>
					</span>
				)}
			</Popover>
		);

		let commentCount = this.props.row.comment_count;

		// Add events from tickets
		const myTickets = this.getRowTickets();
		for (let i = 0; i < myTickets.length; i++) {
			commentCount += myTickets[i].event_count;
		}

		return (
			<div className="board-row">
				<div style={{ height: 36, display: "flex", opacity: this.props.row.id ? null : 0.5 }}>
					<div
						className="fixed-column column-title"
						style={{
							display: "flex",
							flexBasis: BoardHelper.getBoard(this.props.board_id)?.title_width,
							minWidth: BoardHelper.getBoard(this.props.board_id)?.title_width,
							maxWidth: BoardHelper.getBoard(this.props.board_id)?.title_width,
						}}
					>
						<div style={{ width: "100%" }}>
							<LegacyStack spacing="none" wrap={false}>
								<div className="board-spacer">{actions}</div>
								<LegacyStack.Item fill>
									<div className="board-column" style={{ width: "100%" }}>
										<LegacyStack spacing="none" wrap={false}>
											<div className="selectbox" style={{ backgroundColor: group.color }}>
												<div>
													<Checkbox checked={this.props.selected} onChange={this.toggleSelection.bind(this)} />
												</div>
											</div>
											<LegacyStack.Item fill>
												<div style={{ position: "relative" }}>
													{this.state.editingTitle ? (
														<div
															onClick={(event) => {
																event.stopPropagation();
															}}
															style={{ padding: 5, paddingLeft: 6 }}
														>
															<div
																style={{ border: "1px dashed #777" }}
																onDragStart={(event) => {
																	event.stopPropagation();
																	event.preventDefault();
																}}
																draggable
															>
																<input
																	ref={(input) => {
																		if (input) {
																			input.focus();
																		}
																	}}
																	onKeyDown={(event) => {
																		if (event.key === "Enter") {
																			this.changeRowTitle();
																		} else if (event.key === "Escape") {
																			this.setState({ title: this.props.row.title, editingTitle: false });
																		}
																	}}
																	onChange={(event) => {
																		this.setState({ title: event.target.value });
																	}}
																	onBlur={this.changeRowTitle.bind(this)}
																	type="text"
																	value={this.state.title}
																	style={{ display: "block", width: "100%", border: 0 }}
																/>
															</div>
														</div>
													) : (
														<LegacyStack wrap={false} spacing="none">
															<LegacyStack.Item fill>
																<Tooltip content={this.props.row.title}>
																	<a
																		style={{ paddingLeft: 10, paddingTop: 5, display: "block" }}
																		onClick={() => {
																			if (this.props.openRow) {
																				this.props.openRow(this.props.row);
																			} else {
																				this.setState({ showRowSheet: true });
																			}
																		}}
																	>
																		<span
																			className="overflow-ellipsis board-row-title"
																			style={{
																				maxWidth: "100%",
																				display: "inline-block",
																				position: "relative",
																				cursor: "pointer",
																				paddingTop: 1,
																			}}
																		>
																			{this.props.row.title}
																		</span>
																	</a>
																</Tooltip>
															</LegacyStack.Item>
															<div
																className="board-display-hover"
																style={{ position: "absolute", right: 0, top: 0 }}
																onClick={(event) => {
																	event.stopPropagation();
																}}
															>
																<a
																	onClick={() => {
																		this.setState({ editingTitle: true });
																	}}
																	style={{
																		cursor: "pointer",
																		padding: 5,
																		display: "block",
																	}}
																>
																	<Icon source={EditMinor} color="inkLighter" />
																</a>
															</div>
														</LegacyStack>
													)}
												</div>
											</LegacyStack.Item>

											{this.props.row.archived && (
												<PolarisTooltip content={this.props.t("row.archived", "Arkiverad")}>
													<div style={{ padding: "5px" }}>
														<Icon source={ArchiveMajor} color="subdued" />
													</div>
												</PolarisTooltip>
											)}
											<a
												onClick={() => {
													this.setState({ showRowSheet: true, initialTabIndex: 1 });
												}}
												className={"comment-button" + (commentCount ? " have_comment" : " no_comment")}
												style={{
													cursor: "pointer",
													padding: 5,
													display: "block",
													position: "relative",
												}}
											>
												<Icon source={ChatMajor} color={commentCount ? "indigo" : null} />
												{commentCount ? (
													<div
														style={{
															position: "absolute",
															top: 7,
															left: 7,
															width: 16,
															height: 16,
															borderRadius: 8,
															lineHeight: "16px",
															color: "blue",
															fontSize: 9,
															backgroundColor: "white",
															textAlign: "center",
														}}
													>
														{commentCount + ""}
													</div>
												) : null}
											</a>
										</LegacyStack>
									</div>
								</LegacyStack.Item>
							</LegacyStack>
						</div>
					</div>
					{BoardHelper.getBoard(this.props.board_id)?.columns?.map((column) => (
						<BoardCell
							key={column.id}
							board={BoardHelper.getBoard(this.props.board_id)}
							column={column}
							row={this.props.row}
							onUpdateValue={this.props.onUpdateValue}
							history={this.props.history}
						/>
					))}
					{!this.shouldShowArchives() && (
						<div
							className="board-column"
							style={{
								display: "flex",
								minWidth: 40,
								flex: 1,
							}}
						/>
					)}
					<RowSheet
						open={this.state.showRowSheet}
						row={BoardHelper.getRow(this.props.row.id)}
						board={BoardHelper.getBoard(this.props.board_id)}
						initialTabIndex={this.state.initialTabIndex}
						onUpdateRow={() => {}}
						onClose={() => {
							this.setState({ showRowSheet: false, initialTabIndex: null });
						}}
					/>
					{this.state.failed_move_group ? (
						<MissingColumnConfirmationModal
							open
							loading={this.state.saving}
							board={this.state.failed_move_board}
							columns={this.state.missing_columns}
							mapped_columns={this.state.mapped_columns}
							onChange={(v) => {
								this.setState({ missing_columns: v });
							}}
							onConfirm={() => {
								this.moveRowToGroup(this.state.failed_move_group, true);
							}}
							onClose={() => {
								this.setState({ failed_move_group: null });
							}}
						/>
					) : null}
				</div>
			</div>
		);
	}
}
export default withTranslation(["board", "row", "common"], { withRef: true })(BoardRow);
