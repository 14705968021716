import { WithTranslation, withTranslation } from "react-i18next";
import { InfoWrapper } from "../CustomFields/styles";
import ResourceListManager from "../ResourceListManager";
import ItemCard from "src/js/components/ItemCard";
import Colors from "src/js/Colors";
import { useCallback, useContext } from "react";
import Badge from "src/js/components/Badge";
import CategoryContext from "./context";

type CategoriesCustomFieldsProps = {
	history: any;
} & WithTranslation;

const CategoriesCustomFields = ({ t, history }: CategoriesCustomFieldsProps) => {
	const { id } = useContext(CategoryContext);

	const renderItem = useCallback(
		(item: CustomFieldType) => {
			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/customfields/${item.id}`, {
							data: item,
						});
					}}
					title={
						<>
							{item.title}
							{item.connected ? (
								<Badge color={Colors.green}>{t("common.connected", "Ansluten")}</Badge>
							) : (
								<Badge color={Colors.grey}>{t("common.notConnected", "Ej Ansluten")}</Badge>
							)}
						</>
					}
					leftColumn={[
						{
							label: t("customfields.status", "Status"),
							value: item.disabled ? t("common.inactive", "Inaktiv") : t("common.active", "Aktiv"),
						},
						{
							label: t("customfields.description", "Beskrivning"),
							value: item.description,
						},
						{
							label: t("customfields.articles_display", "Artiklar"),
							value: item.articles_display ?? "Inga artiklar",
						},
						{
							label: t("customfields.categories_display_string", "Kategorier"),
							value: item.categories_display ?? "Inga kategorier",
						},
					]}
					rightColumn={[
						{
							label: t("customfields.type", "Typ"),
							value: item.type,
						},
						{
							label: t("customfields.default_value", "Standardvärde"),
							value: item.default_value,
						},
					]}
				/>
			);
		},
		[history, t]
	);
	return (
		<InfoWrapper>
			<ResourceListManager
				appliedFilters={[
					{
						key: "disabled",
						value: "0",
					},
				]}
				onClick={(item) => {
					history.push(`/admin/customfields/${item.id}`, {
						data: item,
					});
				}}
				params={{ category_id: id }}
				resourceHandle={"custom_fields"}
				filterKey={"custom_fields"}
				history={history}
				renderItem={renderItem}
				resourceUrl="/api/custom_fields.json"
				resourceName={{ singular: t("customfields.singular", "Artikel"), plural: t("customfields.plural", "Artiklar") }}
				addRemoveUrl={`/api/categories/${id}/custom_fields.json`}
			/>
		</InfoWrapper>
	);
};
export default withTranslation(["category", "common"])(CategoriesCustomFields);
