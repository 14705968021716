import React, { Component } from "react";
import $ from "jquery";
/* eslint-disable no-console */

class Tooltip extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onMouseOver(e) {
		if (!this.props.content) return;

		$(".board-tooltip").remove();
		if ($(e.target).closest(".board-column").find(".overflow-ellipsis").length > 0) {
			if (
				$(e.target).closest(".board-column").find(".overflow-ellipsis")[0].scrollWidth <
				$(e.target).closest(".board-column").find(".overflow-ellipsis")[0].clientWidth
			) {
				return;
			}
		}

		const topOffset =
			parseFloat($(e.target).closest(".board-column").offset().top) - 55 + parseFloat($(e.target).closest(".board-column").height()) + 5;
		const left = $(e.target).closest(".board-column").offset().left - 100;
		$(
			// eslint-disable-next-line quotes
			'<div class="board-tooltip" style="z-index:999999;pointer-events:none;position:absolute;top:' +
				topOffset +
				"px;left:" +
				left +
				"px;width:" +
				$(e.target).closest(".board-column").width() +
				// eslint-disable-next-line quotes
				'px;"><div style="padding-left:10px;padding-right:10px;overflow:hidden;color:white;background-color:var(--main3);border-radius:5px;padding:3px;work-break:break-all;overflow-wrap: anywhere;">' +
				this.props.content +
				"</div></div>"
		)
			.appendTo(".board-container")
			.hide()
			.fadeIn(200);
	}

	onMouseOut(e) {
		$(".board-tooltip").remove();
	}

	render() {
		return (
			<div
				style={{ width: "100%", height: "100%", ...(this.props.style || {}) }}
				onMouseEnter={this.onMouseOver.bind(this)}
				onMouseLeave={this.onMouseOut.bind(this)}
				onMouseDown={this.onMouseOut.bind(this)}
			>
				{this.props.children}
			</div>
		);
	}
}
export default Tooltip;
