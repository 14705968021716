/* eslint-disable react-compiler/react-compiler */
import React, { useCallback, useMemo, useState } from "react";
import PdfTemplateSheet from "../components/PdfTemplateSheet";
import { store } from "../store";

type PDFContextValue = {
	open: boolean;
	handleOpen: (data: any, saveHandler?: any, template?: any) => void;
	handleClose: () => void;
	data: any;
	setData: (data: any) => void;
	saveHandler?: any;
	setSaveHandler?: (handler: any) => void;
	setTemplate?: (template: any) => void;
};

export const PDFContext = React.createContext({} as PDFContextValue);

const PDFContextProvider = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [data, setData] = useState<any>({});
	const [additionalProps, setAdditionalProps] = useState<any>({});
	const [template, setTemplate] = useState<any>();
	const saveHandler = React.useRef<any>(null);
	const setSaveHandler = (handler) => {
		saveHandler.current = handler;
	};

	const handleOpen = useCallback((data, { saveHandler, template, additionalProps, ...rest }: any = {}) => {
		setData(data);
		setAdditionalProps({
			...(additionalProps || {}),
			...(rest || {}),
		});

		setSaveHandler(saveHandler);
		setTemplate(template);

		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
		additionalProps?.onClose?.();
	}, [additionalProps]);

	const value = useMemo(
		() => ({
			open,
			handleOpen,
			handleClose,
			data: {
				user: store.getState().user,
				account: store.getState().account,
				...(data || {}),
			},
			setData,
			saveHandler: saveHandler.current,
			setSaveHandler,
			setTemplate,
			additionalProps,
		}),
		[open, data, saveHandler, handleOpen, additionalProps, handleClose]
	);

	return (
		<PDFContext value={value}>
			{children}
			<PdfTemplateSheet
				// onSave={this.addFile.bind(this)}
				// save
				template={template}
				saveHandler={saveHandler.current}
				requireName
				data={data}
				open={open}
				{...additionalProps}
				onClose={handleClose}
			/>
		</PDFContext>
	);
};
export default PDFContextProvider;
